import React, {Component} from 'react';
import {Container, Col, Row, InputGroup, FormControl, Accordion, Card, Button} from "react-bootstrap";
import {withAuth0} from "@auth0/auth0-react";

class HelpComponent extends Component {

    constructor(props) {
        super(props);

    }

    render() {

        const {isAuthenticated} = this.props.auth0;

        return (
            <div style={{textAlign: "center"}}>
                <Container fluid>
                    <Row>
                        <Col sm={12}>
                            <h3><strong>Wall From Us Group Ecards FAQ</strong></h3>
                            <br></br>
                            <p><strong>How many people can I send a wall to?</strong></p>
                            <p>You can send your wall to a maximum of 10 recipients.</p>
                            <p><strong>How many people can can contribute to my wall?</strong></p>
                            <p>Up to 100 people can contribute to your wall.</p>
                            <p><strong>How do I download my wall?</strong></p>
                            <p>Using Google's Chrome browser, you can save your wall in MHT file format.</p>
                            <ul>
                                <p> 1. Right click on your wall background. </p>
                                <p> 2. Click "Save As". </p>
                                <p> 3. Choose a filename and select the format "Web page, Single File" </p>
                                <p> All the contents of your wall can now be viewed in the Chrome browser offline.</p>
                            </ul>
                            <p><strong>How many walls can I make?</strong></p>
                            <p>You can make an unlimited amount of drafts and activated walls.</p>
                            <p><strong>How long will my wall exist for?</strong></p>
                            <p>Each wall will be served until the site ceases to exist, or the creator or any recipients inform us that they would like to take the wall down.</p>
                            <p><strong>How long will my GIFs work for?</strong></p>
                            <p>GIFs are provided by a third party and cannot be guaranteed to always exist.</p>
                            <p><strong>Who can see my wall?</strong></p>
                            <p>Your wall will be available publicly, but with a long unique ID in the URL that you can share as you please.</p>
                            <p><strong>Who can contribute to my wall?</strong></p>
                            <p>The public can write, edit and delete all posts on your wall, before and after your wall has been sent to the recipient.</p>
                            <p><strong>How will I know my wall has been sent?</strong></p>
                            <p>You will receive an e-mail when your wall has been sent to the recipients.</p>
                            <p><strong>Will my wall be ad-free?</strong></p>
                            <p>Only premium walls will be ad free</p>
                            <p><strong>Do I have to sign up to contribute to a wall</strong></p>
                            <p>No</p>
                            <p><strong>What is your refund policy?</strong></p>
                            <p>Unfortunately refunds will not be possible.</p>
                        </Col>
                    </Row>
                    <br></br>
                    <Row>
                        <Col sm={12}>
                            <h3><strong>Still Need Help?</strong></h3>
                            <p>If you have any queries or would like support please email support@wallfromus.com</p>
                        </Col>

                    </Row>
                </Container>
            </div>)
    }


}

export default withAuth0(HelpComponent);