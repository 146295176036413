import React, {Component} from "react";
import {withRouter} from "react-router-dom";
import {resetEditProgressWall} from "../actions/edit";
import {Button, Col, Row} from "react-bootstrap";
import {faArrowRight} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {withAuth0} from "@auth0/auth0-react";
import {connect} from "react-redux";
import ScaleLoader from "react-spinners/ScaleLoader";
import axios from "axios";
import {Redirect} from "react-router-dom";


class EditConfirmationComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            wallActivated: false,
            wallUpdating: true
        }
        this.fetchPaymentStatus = this.fetchPaymentStatus.bind(this);

        this.confirmationType = this.props.match.params.confirmationtype

    }

    componentWillUnmount() {
        if (this.timer !== undefined){
            clearTimeout(this.timer);
        }
    }

    clearEditStateAndRedirect(uuid) {
        this.props.dispatch(resetEditProgressWall())
        return this.props.history.push("/editwall/designwall/" + uuid)
    }

    fetchPaymentStatus() {

        if (this.confirmationType !== 'payment') {
            return
        }

        let config = {
            headers: {
                Authorization: `Bearer ${this.props.token}`,
                "Access-Control-Allow-Origin": "*"
            }
        }

        axios.get(`${process.env.REACT_APP_SERVER_URL}paymentstatus/` + this.props.match.params.uuid,
            config).then(result => {
            if (result.data === true) {
                this.setState(
                    {wallActivated: true, wallUpdating: false}
                )
            } else {
                this.setState(
                    {wallActivated: false, wallUpdating: false}
                )
            }

        }).catch(err => {
            this.setState(
                {wallActivated: false, wallUpdating: false}
            )
        })
    }

    sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    render() {

        const {isAuthenticated, isLoading} = this.props.auth0;

        let buttonStyle = {
            backgroundColor: " #8639F1",
            borderColor: " #8639F1",
            color: "#FFFFFF",
            marginRight: "20px",
            width: "100%",
            boxShadow: "0px 2px 5px -1px #333",
        }

        this.props.updateCurrentBreadcrumb("Confirmation")
        let confirmationType = this.props.match.params.confirmationtype
        let uuid = this.props.match.params.uuid

        if (isLoading) {
            return (
                <div>
                    <h6 style={{textAlign: "center"}}>Loading</h6>
                </div>
            )
        } else if (!isAuthenticated && !isLoading) {
            return (
                <div>
                    <Redirect to={'/home'}/>
                </div>
            )
        } else {

            let wallActivated = this.state.wallActivated
            let wallUpdating = this.state.wallUpdating

            if (this.props.token !== undefined &&
                this.props.user !== undefined &&
                this.timer === undefined){
                this.timer = setTimeout(() => this.fetchPaymentStatus(), 7000);
            }

            return (


                <div>
                    {confirmationType === 'draft' && <div>
                        <h6>Wall saved</h6>
                        <Row>
                            <Col sm={3}>
                                <Button style={buttonStyle} onClick={() => this.clearEditStateAndRedirect(uuid)}>
                                    Edit Wall <FontAwesomeIcon icon={faArrowRight}/>
                                </Button>
                            </Col>
                        </Row>
                    </div>}
                    {confirmationType === 'editactive' && <div>
                        <h6>Wall Saved</h6>
                        <br></br>
                    </div>}
                    {confirmationType === 'payment'  && wallActivated === true && <div>
                        <h6>Wall Updated</h6>
                        <br></br>
                    </div>}
                    {(confirmationType === 'editactive' || (confirmationType === 'payment' && wallActivated === true)) &&
                    <div>
                        <br></br>
                        <Row>
                            <Col sm={3}>
                                <Button style={buttonStyle} onClick={() => this.clearEditStateAndRedirect(uuid)}>
                                    Edit wall <FontAwesomeIcon icon={faArrowRight}/>
                                </Button>
                            </Col>
                        </Row>
                        <br></br>
                        <Row>
                            <Col sm={3}>
                                <Button style={buttonStyle} href={`${process.env.REACT_APP_UI_URL}viewwall/` + uuid + "?delivery=true"}
                                        target="_blank">
                                    View wall in new tab <FontAwesomeIcon icon={faArrowRight}/>
                                </Button>
                            </Col>
                        </Row>
                    </div>
                    }
                    {((confirmationType === 'payment' || confirmationType === 'activatefreewall') && wallUpdating === true) &&
                    <div style = {{textAlign: "center", marginTop: "50px"}}>
                        <ScaleLoader style={{display: "inline-block"}} color={"#8639F1"} loading={true} size={150}/>
                    </div>}
                    {(confirmationType === 'payment' && wallUpdating === false && wallActivated === false) &&
                    <div>
                        <h6 style={{textAlign: "center"}}>Your Payment has not completed yet, you will be e-mailed when
                            it completes.</h6>
                    </div>}
                </div>
            )
        }
    }
}

function mapStateToProps(state) {

    return {
        token: state.getIn(["centralState", "accessToken"]),
        user: state.getIn(["centralState", "user"]),
    }
}

export default withRouter(withAuth0

(
    connect(mapStateToProps)

    (
        EditConfirmationComponent
    )))
;