import React, {Component} from "react";
import 'react-multi-email/style.css';
import './ContributionComponent.css'
import {switchCentreBoolToTextValue} from "../utils/utils";
import ReactCrop from "react-image-crop";
import {faTrash, faPen} from '@fortawesome/free-solid-svg-icons'
import {Col, Container, Row} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import TextareaAutosize from "react-textarea-autosize";


class ContributionComponent extends Component {

    constructor(props) {
        super(props);
    }

    render() {

        let contributionImage;


        if (this.props.embeddedGifUrl == null) {
            if (this.props.cropActivated === false){
                contributionImage = <img style = {{width: "100%"}} src={this.props.s3Url}/>
            } else {
                contributionImage = <ReactCrop src={this.props.s3Url}
                                               crop={this.props.crop}
                                               onChange={newCrop => this.props.setCrop(newCrop)}
                                               onImageLoaded={image => this.props.setImage(image)}/>;
            }
        } else {
            contributionImage = <img style = {{width: "100%"}} src={this.props.embeddedGifUrl}/>
        }

        let textArea = <TextareaAutosize
            style={{
                whiteSpace: "pre-line",
                opacity: 1,
                backgroundColor: "rgba(0, 0, 0, 0.0)",
                borderColor: "rgba(0, 0, 0, 0.0)",
                borderRadius: "5px",
                overflow: "hidden",
                caretColor: "rgba(0,0,0,0)",
                textAlign: switchCentreBoolToTextValue(this.props.isCentred),
                width: "100%",
                resize: "none",
                fontSize: this.props.fontSize,
                color: this.props.fontColour,
                fontFamily: this.props.font
            }}
            value={this.props.text}>

        </TextareaAutosize>

        return (
            <div className="contribution"
                 style={{
                     fontSize: this.props.fontSize,
                     color: this.props.fontColour,
                     fontFamily: this.props.font,
                     textAlign: switchCentreBoolToTextValue(this.props.isCentred),
                     overflow: "hidden",
                     padding: "2%",
                     boxShadow: "0px 2px 5px -1px #333",
                 }}>
                {this.props.isMessageAtTop && textArea}
                {contributionImage}
                {!this.props.isMessageAtTop && textArea}
                {this.props.editable &&
                    <div
                        style={{
                            fontSize: this.props.fontSize,
                            color: "#CCC"
                        }}>
                        <Container fluid>
                            <Row>
                                <Col sm={10}></Col>
                                <Col sm={1}>
                                <div onClick={() => this.props.activateDeleteModal(this.props.id)} style={{"cursor": "pointer"}}>
                                    <a><FontAwesomeIcon style={{fontSize: 18}} icon={faTrash}/></a>
                                </div>
                                </Col>
                                <Col sm={1}>
                                    <div onClick={() => this.props.activateEditModal(this.props.id)} style={{"cursor": "pointer"}}>
                                        <a><FontAwesomeIcon style={{fontSize: 18}} icon={faPen}/></a>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>
}

            </div>
        )
    }
}

export default ContributionComponent