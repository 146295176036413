import React, {Component} from 'react';
import {Container, Col, Row, InputGroup, FormControl, Accordion, Card, Button} from "react-bootstrap";
import Select from "react-select";
import {
    updateDefaultWallSearchResults, updateDefaultWallSelection,
    updateDefaultWallTagSelection
} from "../actions";
import {
    updateDefaultWallTagSelection as updateEditTagSelection,
    updateDefaultWallSelection as updateEditWallSelection,
    updateDefaultWallSearchResults as updateEditWallSearchResults
} from "../actions/edit"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMagnifyingGlass} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";

class ThemeSearchModalComponent extends Component {

    constructor(props) {
        super(props);

        this.state = {
            sendingSearchRequest: false
        }

        this.handleTagSelectionChange = this.handleTagSelectionChange.bind(this);
        this.selectWall = this.selectWall.bind(this);
        this.searchForWalls = this.searchForWalls.bind(this);
    }

    handleTagSelectionChange(tags) {

        if (this.props.isCreate === true) {

            this.props.dispatch(updateDefaultWallTagSelection({
                    searchTags: tags
                }
            ))
        } else {
            this.props.dispatch(updateEditTagSelection({
                    searchTags: tags
                }
            ))
        }
    }

    selectWall(searchResult) {

        if (this.props.isCreate === true) {

            this.props.dispatch(updateDefaultWallSelection({
                    defaultWall: searchResult
                }
            ))

            this.props.handleBackgroundSelectChange(false)
        } else {

            this.props.dispatch(updateEditWallSelection({
                    defaultWall: searchResult
                }
            ))
            this.props.handleBackgroundSelectChange(false)
        }
    }

    searchForWalls() {

        let tagIds = this.props.searchTags.map((obj) => {
            return obj["value"]
        }).toString()

        if (this.props.token !== "" &&
            this.props.token !== undefined &&
            this.state.sendingSearchRequest === false) {

            let config = {
                headers: {
                    Authorization: `Bearer ${this.props.token}`,
                    "Access-Control-Allow-Origin": "*"
                }
            }

            this.setState({sendingSearchRequest: true})

            axios.get(`${process.env.REACT_APP_SERVER_URL}defaultwall/search?tags=${tagIds}`, config).then(result => {

                    if (this.props.isCreate === true) {
                        this.props.dispatch(updateDefaultWallSearchResults({searchResults: result.data}))
                    } else {
                        this.props.dispatch(updateEditWallSearchResults({searchResults: result.data}))
                    }
                }
            ).catch(err => {
            }).finally(result => {
                this.setState({sendingSearchRequest: false})
            })
        }
    }

    render() {

        let imageStyle = {
            width: "100%",
            height: "90%",
            margin: "auto",
            display: "block",
        }

        let thumbnailStyle = {
            width: "75px",
            height: "75px",
            margin: "auto",
            float: "left",
        }

        let selectWallFunction = this.selectWall

        return (
            <div>
                <Container fluid>
                    <Row>
                        <Col sm={6}>
                            <br></br>
                            <h1>Search Walls</h1>
                            <br/>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={6}>
                            <Select options={this.props.tagOptions}
                                    isMulti
                                    placeholder="Select Tags"
                                    value={this.props.searchTags}
                                    isOptionDisabled={() => this.props.searchTags.length >= 5}
                                    onChange={(event) => this.handleTagSelectionChange(event)}
                            />
                        </Col>

                        <Col sm={3}>
                            <Button
                                onClick={() => this.searchForWalls()}
                                style={this.props.buttonStyle}>
                                Search Walls <FontAwesomeIcon icon={faMagnifyingGlass}/>
                            </Button>
                        </Col>
                    </Row>
                    <br/>

                    {this.props.previouslySelected.length > 0 && <div>
                        <Row>
                            <h6>Previously Selected</h6>
                        </Row>
                        <Row>
                            <span>
                            {
                                this.props.previouslySelected.map(function (defaultWall, i) {
                                        return (<div style={{"display": "inline-block", "margin-right": "10px"}}><a href={"#"} onClick={() => selectWallFunction(defaultWall)}>
                                            <img style={thumbnailStyle} src={defaultWall.s3Path}/></a><br/></div>);
                                    })
                                }
                                </span>
                        </Row>
                        <br></br>
                    </div>}
                    <Row>
                        <Col sm={4}>
                            {
                                this.props.searchResults.map(function (searchResult, i) {
                                    if (i % 3 === 0) {
                                        return (<div><a href={"#"} onClick={() => selectWallFunction(searchResult)}>
                                            <img style={imageStyle} src={searchResult.s3Path}/></a><br/></div>);
                                    }
                                })}
                        </Col>
                        <Col sm={4}>
                            {
                                this.props.searchResults.map(function (searchResult, i) {
                                    if (i % 3 === 1) {
                                        return (<div><a href={"#"} onClick={() => selectWallFunction(searchResult)}>
                                            <img style={imageStyle} src={searchResult.s3Path}/></a><br/></div>);
                                    }
                                })}
                        </Col>
                        <Col sm={4}>
                            {
                                this.props.searchResults.map(function (searchResult, i) {
                                    if (i % 3 === 2) {
                                        return (<div><a href={"#"} onClick={() => selectWallFunction(searchResult)}>
                                            <img style={imageStyle} src={searchResult.s3Path}/></a><br/></div>);
                                    }
                                })}
                        </Col>

                    </Row>
                    <br></br>
                </Container>
            </div>)
    }


}

export default ThemeSearchModalComponent;