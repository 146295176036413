import React from "react";

export const switchCentreBoolToTextValue = (param) => {
    switch (param) {
        case true:
            return 'center';
        default:
            return 'justify';
    }
}

export const switchTransparencyBool = (param) => {
    switch (param) {
        case true:
            return 'rgba(0, 0, 0, 0.0)';
        default:
            return 'rgba(256, 256, 256, 1)';
    }
}

export const noShadowIftransparent = (param) => {
    switch (param) {
        case true:
            return "0px 0px 0px 0px #333";
        default:
            return "0px 2px 5px -1px #333";
    }
}

export const extractConfettiColorsOnly = (colorsAndIds) => {
    let colors = []
    for (const idWithHex of colorsAndIds) { colors.push(idWithHex.hex) }
    return colors
}

export const assignConfettiColorsIds = (colors) => {
    let colorsAndIds = []
    colors.forEach((color, i) => colorsAndIds.push({"id": i, "hex": color}))
    return colorsAndIds
}

export const fonts = [
    {value: 'Akaya Telivigala', label: <h6 style={{fontFamily: 'Akaya Telivigala'}}>Akaya Telivigala</h6>},
    {value: 'Alex Brush', label: <h6 style={{fontFamily: 'Alex Brush'}}>Alex Brush</h6>},
    {value: 'Allura', label: <h6 style={{fontFamily: 'Allura'}}>Allura</h6>},
    {value: 'Arial, sans-serif', label: <h6 style={{fontFamily: 'Arial, sans-serif'}}>Arial</h6>},
    {value: 'Bad Script', label: <h6 style={{fontFamily: 'Bad Script'}}>Bad Script</h6>},
    {value: 'Brush Script MT', label: <h6 style={{fontFamily: 'Brush Script MT'}}>Brush Script</h6>},
    {value: 'Caveat', label: <h6 style={{fontFamily: 'Caveat'}}>Caveat</h6>},
    {value: 'Charm', label: <h6 style={{fontFamily: 'Charm'}}>Charm</h6>},
    {value: 'Comic sans MS', label: <h6 style={{fontFamily: 'Comic sans MS'}}>Comic sans MS</h6>},
    {value: 'Cookie', label: <h6 style={{fontFamily: 'Cookie'}}>Cookie</h6>},
    {value: 'Courgette', label: <h6 style={{fontFamily: 'Courgette'}}>Courgette</h6>},
    {value: 'Courier New, monospace', label: <h6 style={{fontFamily: 'Courier New, monospace'}}>Courier New</h6>},
    {value: 'Dancing Script', label: <h6 style={{fontFamily: 'Dancing Script'}}>Dancing Script</h6>},
    {value: 'Festive', label: <h6 style={{fontFamily: 'Festive'}}>Festive</h6>},
    {value: 'Garamond', label: <h6 style={{fontFamily: 'Garamond'}}>Garamond</h6>},
    {value: 'Georgia', label: <h6 style={{fontFamily: 'Georgia'}}>Georgia</h6>},
    {value: 'Gloria Hallelujah', label: <h6 style={{fontFamily: 'Gloria Hallelujah'}}>Gloria Hallelujah</h6>},
    {value: 'Great Vibes', label: <h6 style={{fontFamily: 'Great Vibes'}}>Great Vibes</h6>},
    {value: 'Helvetica, sans-serif', label: <h6 style={{fontFamily: 'Helvetica, sans-serif'}}>Helvetica</h6>},
    {value: 'Homemade Apple', label: <h6 style={{fontFamily: 'Homemade Apple'}}>Homemade Apple</h6>},
    {value: 'Indie Flower', label: <h6 style={{fontFamily: 'Indie Flower'}}>Indie Flower</h6>},
    {value: 'Italianno', label: <h6 style={{fontFamily: 'Italianno'}}>Italianno</h6>},
    {value: 'Josefin Sans', label: <h6 style={{fontFamily: 'Josefin Sans'}}>Josefin Sans</h6>},
    {value: 'Lobster', label: <h6 style={{fontFamily: 'Lobster'}}>Lobster</h6>},
    {value: 'Love Light', label: <h6 style={{fontFamily: 'Love Light'}}>Love Light</h6>},
    {value: 'Nothing You Could Do', label: <h6 style={{fontFamily: 'Nothing You Could Do'}}>Nothing You Could Do</h6>},
    {value: 'Ole', label: <h6 style={{fontFamily: 'Ole'}}>Ole</h6>},
    {value: 'Pacifico', label: <h6 style={{fontFamily: 'Pacifico'}}>Pacifico</h6>},
    {value: 'Parisienne', label: <h6 style={{fontFamily: 'Parisienne'}}>Parisienne</h6>},
    {value: 'Permanent Marker', label: <h6 style={{fontFamily: 'Permanent Marker'}}>Permanent Marker</h6>},
    {value: 'Redressed', label: <h6 style={{fontFamily: 'Redressed'}}>Redressed</h6>},
    {value: 'Rock Salt', label: <h6 style={{fontFamily: 'Rock Salt'}}>Rock Salt</h6>},
    {value: 'Sacramento', label: <h6 style={{fontFamily: 'Sacramento'}}>Sacramento</h6>},
    {value: 'Satisfy', label: <h6 style={{fontFamily: 'Satisfy'}}>Satisfy</h6>},
    {value: 'Shadows Into Light', label: <h6 style={{fontFamily: 'Shadows Into Light'}}>Shadows Into Light</h6>},
    {value: 'Shizuru', label: <h6 style={{fontFamily: 'Shizuru'}}>Shizuru</h6>},
    {value: 'Tahoma, sans-serif', label: <h6 style={{fontFamily: 'Tahoma, sans-serif'}}>Tahoma</h6>},
    {value: 'Tangerine', label: <h6 style={{fontFamily: 'Tangerine'}}>Tangerine</h6>},
    {value: 'Times New Roman', label: <h6 style={{fontFamily: 'Times New Roman'}}>Times New Roman</h6>},
    {value: 'Trebuchet MS, sans-serif', label: <h6 style={{fontFamily: 'Trebuchet MS, sans-serif'}}>Trebuchet MS</h6>},
    {value: 'Verdana, sans-serif', label: <h6 style={{fontFamily: 'Verdana, sans-serif'}}>Verdana</h6>},
]

export const fontColours = [
    {value: '#000000', label: <div style={{color: '#000000'}}>&#9632;</div>},
    {value: '#FFFFFF', label: <div style={{color: '#FFFFFF'}}>&#9632;</div>},
    {value: '#B0171F', label: <div style={{color: '#B0171F'}}>&#9632;</div>},
    {value: '#DC143C', label: <div style={{color: '#DC143C'}}>&#9632;</div>},
    {value: '#CD8C95', label: <div style={{color: '#CD8C95'}}>&#9632;</div>},
    {value: '#FFAEB9', label: <div style={{color: '#FFAEB9'}}>&#9632;</div>},
    {value: '#8B5F65', label: <div style={{color: '#8B5F65'}}>&#9632;</div>},
    {value: '#FF82AB', label: <div style={{color: '#FF82AB'}}>&#9632;</div>},
    {value: '#8B475D', label: <div style={{color: '#8B475D'}}>&#9632;</div>},
    {value: '#FF3E96', label: <div style={{color: '#FF3E96'}}>&#9632;</div>},
    {value: '#CD3278', label: <div style={{color: '#CD3278'}}>&#9632;</div>},
    {value: '#8B2252', label: <div style={{color: '#8B2252'}}>&#9632;</div>},
    {value: '#FF1493', label: <div style={{color: '#FF1493'}}>&#9632;</div>},
    {value: '#DA70D6', label: <div style={{color: '#DA70D6'}}>&#9632;</div>},
    {value: '#EE7AE9', label: <div style={{color: '#EE7AE9'}}>&#9632;</div>},
    {value: '#8B4789', label: <div style={{color: '#8B4789'}}>&#9632;</div>},
    {value: '#FF00FF', label: <div style={{color: '#FF00FF'}}>&#9632;</div>},
    {value: '#800080', label: <div style={{color: '#800080'}}>&#9632;</div>},
    {value: '#B452CD', label: <div style={{color: '#B452CD'}}>&#9632;</div>},
    {value: '#9932CC', label: <div style={{color: '#9932CC'}}>&#9632;</div>},
    {value: '#4B0082', label: <div style={{color: '#4B0082'}}>&#9632;</div>},
    {value: '#8A2BE2', label: <div style={{color: '#8A2BE2'}}>&#9632;</div>},
    {value: '#483D8B', label: <div style={{color: '#483D8B'}}>&#9632;</div>},
    {value: '#6A5ACD', label: <div style={{color: '#6A5ACD'}}>&#9632;</div>},
    {value: '#0000FF', label: <div style={{color: '#0000FF'}}>&#9632;</div>},
    {value: '#00008B', label: <div style={{color: '#00008B'}}>&#9632;</div>},
    {value: '#3D59AB', label: <div style={{color: '#3D59AB'}}>&#9632;</div>},
    {value: '#3A5FCD', label: <div style={{color: '#3A5FCD'}}>&#9632;</div>},
    {value: '#708090', label: <div style={{color: '#708090'}}>&#9632;</div>},
    {value: '#1E90FF', label: <div style={{color: '#1E90FF'}}>&#9632;</div>},
    {value: '#68838B', label: <div style={{color: '#68838B'}}>&#9632;</div>},
    {value: '#00868B', label: <div style={{color: '#00868B'}}>&#9632;</div>},
    {value: '#2F4F4F', label: <div style={{color: '#2F4F4F'}}>&#9632;</div>},
    {value: '#008B8B', label: <div style={{color: '#008B8B'}}>&#9632;</div>},
    {value: '#00C78C', label: <div style={{color: '#00C78C'}}>&#9632;</div>},
    {value: '#458B74', label: <div style={{color: '#458B74'}}>&#9632;</div>},
    {value: '#008B45', label: <div style={{color: '#008B45'}}>&#9632;</div>},
    {value: '#43CD80', label: <div style={{color: '#43CD80'}}>&#9632;</div>},
    {value: '#3D9140', label: <div style={{color: '#3D9140'}}>&#9632;</div>},
    {value: '#8FBC8F', label: <div style={{color: '#8FBC8F'}}>&#9632;</div>},
    {value: '#698B69', label: <div style={{color: '#698B69'}}>&#9632;</div>},
    {value: '#32CD32', label: <div style={{color: '#32CD32'}}>&#9632;</div>},
    {value: '#9ACD32', label: <div style={{color: '#9ACD32'}}>&#9632;</div>},
    {value: '#CDCD00', label: <div style={{color: '#CDCD00'}}>&#9632;</div>},
    {value: '#808000', label: <div style={{color: '#808000'}}>&#9632;</div>},
    {value: '#FFFF00', label: <div style={{color: '#FFFF00'}}>&#9632;</div>},
    {value: '#FFD700', label: <div style={{color: '#FFD700'}}>&#9632;</div>},
    {value: '#8B7500', label: <div style={{color: '#8B7500'}}>&#9632;</div>},
    {value: '#DAA520', label: <div style={{color: '#DAA520'}}>&#9632;</div>},
    {value: '#8B795E', label: <div style={{color: '#8B795E'}}>&#9632;</div>},
    {value: '#FF7F00', label: <div style={{color: '#FF7F00'}}>&#9632;</div>},
    {value: '#FF4500', label: <div style={{color: '#FF4500'}}>&#9632;</div>},
    {value: '#8B3626', label: <div style={{color: '#8B3626'}}>&#9632;</div>},
    {value: '#FF4040', label: <div style={{color: '#FF4040'}}>&#9632;</div>}
]

