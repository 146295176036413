import React, {Component} from "react";
import axios from "axios";

import {withAuth0} from "@auth0/auth0-react";
import {connect} from "react-redux";
import {Button, Col, Container, Row} from "react-bootstrap";
import {Redirect, withRouter} from "react-router-dom";
import {resetEditProgressWall} from "../actions/edit";


class WallsComponent extends Component {

    constructor(props) {
        super(props);

        this.state = {
            wallType: this.props.wallType,
            walls: [],
            initialised: false,
            errors: ""
        }
    }

    clearEditStateAndRedirect(uuid) {
        this.props.dispatch(resetEditProgressWall());
        return this.props.history.push("/editwall/designwall/" + uuid)
    }


    render() {

        if (this.props.token !== undefined && this.props.user !== undefined && this.state.initialised === false) {

            let config = {
                headers: {
                    Authorization: `Bearer ${this.props.token}`,
                    "Access-Control-Allow-Origin": "*"
                }
            }

            axios.get(`${process.env.REACT_APP_SERVER_URL}getWallsForUser/` + encodeURIComponent(this.props.user.sub) + '/' + this.props.wallType,
                config).then(result => {
                this.setState({
                    walls: result.data,
                    initialised: true
                });

            }).catch(err => {
                console.log(`Something went wrong ${err}`);
                this.setState({errors: err.toString(), initialised: true})
            })
        }

        const walls = this.state.walls.map(wall => {

            let buttonStyle = {
                backgroundColor: " #8639F1",
                borderColor: " #8639F1",
                color: "#FFFFFF",
                marginRight: "20px",
                boxShadow: "0px 2px 5px -1px #333",
            }

            let wallType = this.state.wallType;

            return (
                <div>
                    <Row>
                        <Col sm={4}>
                            {wall.deliveryDateTime.replace('T', ' ').slice(0, wall.deliveryDateTime.length - 12)} UTC
                        </Col>
                        <Col sm={4}>
                            {wall.recipientList.map(recipient => <li>{recipient}</li>)}
                        </Col>
                        <Col sm={2}>
                                <Button style={buttonStyle} onClick={() => this.clearEditStateAndRedirect(wall.uuid)}>
                                    Edit Wall
                                </Button>
                            </Col>
                        {(wallType === 'active' || wallType === 'sent') && <Col sm={2}>
                            <Button style={buttonStyle}
                                    href={`${process.env.REACT_APP_UI_URL}viewwall/` + wall.uuid + "?delivery=true"}
                                    target="_blank">
                                View Wall
                            </Button>
                        </Col>}
                    </Row>
                    <hr/>
                </div>
            )
        })

        const {isAuthenticated, isLoading} = this.props.auth0;

        if (isLoading) {
            return (
                <div>
                    <h6 style={{textAlign: "center"}}>Loading</h6>
                </div>
            )
        } else if (!isAuthenticated && !isLoading) {
            return (
                <div>
                    <Redirect to={'/home'}/>
                </div>
            )

        } else if (this.state.errors !== "") {
            return (<div>
                <br></br>
                <Row>
                    <h6>Server Error</h6>
                    <p>{this.state.errors}</p>
                </Row>
            </div>)
        } else {

            let wallType = this.state.wallType

            return (
                <div>
                    <Container>
                        <Row>
                            <h3>{wallType.charAt(0).toUpperCase() + wallType.slice(1)} Walls</h3>
                        </Row>
                        <br/>
                        <br/>
                        <Row>
                            <Col sm={4}>
                                <h6>Send Date and Time</h6>
                            </Col>
                            <Col sm={4}>
                                <h6>Recipient List</h6>
                            </Col>
                            {(wallType === 'active' || wallType === 'sent') &&
                            <Col sm={2}>
                                <h6>Edit Wall</h6>
                            </Col>}
                            {(wallType === 'active' || wallType === 'sent') &&
                            <Col sm={2}>
                                <h6>View Wall</h6>
                            </Col>}
                        </Row>
                        <Row>
                            <hr/>
                        </Row>
                        {walls}
                    </Container>
                </div>
            )
        }
    }
}

function mapStateToProps(state) {

    return {
        token: state.getIn(["centralState", "accessToken"]),
        user: state.getIn(["centralState", "user"]),
    }
}

export default withRouter(withAuth0(connect(mapStateToProps)(WallsComponent)));