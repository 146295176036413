
export function updateWallTitle(payload) {
    return {
        type: 'UPDATE_CREATE_PROGRESS_CARD_TITLE',
        payload: payload
    };
}

export function updateFont({ font }) {
    return {
        type: 'UPDATE_CREATE_PROGRESS_FONT',
        payload: {
            font,
        },
    };
}

export function updateDefaultWallTagSelection({searchTags}){
    return {
        type: 'UPDATE_CREATE_PROGRESS_SEARCH_TAGS',
        payload: {
            searchTags,
        },
    };
}

export function updateDefaultWallSelection({defaultWall}){
    return {
        type: 'UPDATE_CREATE_PROGRESS_DEFAULT_WALL_SELECTED',
        payload: {
            defaultWall,
        },
    };
}

export function updateDefaultWallSearchResults({searchResults}){
    return {
        type: 'UPDATE_CREATE_PROGRESS_SEARCH_RESULTS',
        payload: {
            searchResults,
        },
    };
}

export function populateTagOptions({options}){
    return {
        type: 'POPULATE_CREATE_PROGRESS_TAG_OPTIONS',
        payload: {
            options,
        },
    };
}

export function updateIsPremium({ isPremium }) {
    return {
        type: 'UPDATE_IS_PREMIUM',
        payload: {
            isPremium,
        },
    };
}

export function updateIsConfetti({ isConfetti }) {
    return {
        type: 'UPDATE_CREATE_PROGRESS_IS_CONFETTI',
        payload: {
            isConfetti,
        },
    };
}

export function updateIsCustomise({ isCustomise }) {
    return {
        type: 'UPDATE_CREATE_PROGRESS_IS_CUSTOMISE',
        payload: {
            isCustomise,
        },
    };
}

export function updateViewWallCountry ({country}) {
    return {
        type: 'UPDATE_VIEW_WALL_COUNTRY',
        payload: {
            country
        }
    }
}

export function updateConfettiColors({ confettiColorsAndIds }) {
    return {
        type: 'UPDATE_CREATE_PROGRESS_CONFETTI_COLORS',
        payload: {
            confettiColorsAndIds,
        },
    };
}

export function updateIsTransparent({ isTransparent }) {
    return {
        type: 'UPDATE_CREATE_PROGRESS_IS_TRANSPARENT',
        payload: {
            isTransparent,
        },
    };
}

export function updateIsCentred({ isCentred }) {
    return {
        type: 'UPDATE_CREATE_PROGRESS_IS_CENTRED',
        payload: {
            isCentred,
        },
    };
}

export function updateFontSize({ fontSize }) {
    return {
        type: 'UPDATE_CREATE_PROGRESS_FONT_SIZE',
        payload: {
            fontSize,
        },
    };
}

export function updateFontColour({ fontColour }) {
    return {
        type: 'UPDATE_CREATE_PROGRESS_FONT_COLOUR',
        payload: {
            fontColour,
        },
    };
}

export function updateCurrentBreadcrumb({ currentBreadcrumb }) {
    return {
        type: 'UPDATE_CREATE_PROGRESS_CURRENT_BREADCRUMB',
        payload: {
            currentBreadcrumb,
        },
    };
}

export function setAccessToken({accessToken}) {

    return {
        type: 'UPDATE_ACCESS_TOKEN',
        payload: {
            accessToken
        }
    }
}

export function setUser({user}) {

    return {
        type: 'UPDATE_USER',
        payload: {
            user
        }
    }
}

export function updateDeliveryDate({ deliveryDate }) {
    return {
        type: 'UPDATE_CREATE_PROGRESS_DELIVERY_DATE',
        payload: {
            deliveryDate,
        },
    };
}

export function updateDeliveryTime({ deliveryTime }) {
    return {
        type: 'UPDATE_CREATE_PROGRESS_DELIVERY_TIME',
        payload: {
            deliveryTime
        },
    };
}

export function updateRecipientList({ recipientList }) {
    return {
        type: 'UPDATE_CREATE_PROGRESS_RECIPIENT_LIST',
        payload: {
            recipientList,
        },
    };
}

export function resetCreateProgressWall() {
    return {
        type: 'RESET_CREATE_PROGRESS_WALL',
    };
}



