import React, {Component} from "react";

import {Col, Row, Button, Form, Breadcrumb, Dropdown, Modal} from "react-bootstrap";
import {Link, withRouter} from "react-router-dom"
import TextareaAutosize from 'react-textarea-autosize';
import 'react-image-picker/dist/index.css'
import Picker from 'emoji-picker-react';
import './DesignWallComponent.css'
import img103 from '../images/103.jpg';
import {
    switchCentreBoolToTextValue,
    switchTransparencyBool,
    fonts,
    fontColours,
    noShadowIftransparent, extractConfettiColorsOnly, assignConfettiColorsIds
} from '../utils/utils.js'

import {
    updateWallTitle,
    updateIsCentred,
    updateFont,
    updateFontColour,
    updateFontSize,
    updateIsTransparent,
    updateEditingWall,
    updateIsConfetti,
    updateConfettiColors,
    updateIsCustomise, populateTagOptions
} from '../actions/edit';
import Select from 'react-select'
import {withAuth0} from "@auth0/auth0-react";
import {connect} from "react-redux";

import Carousel, {slidesToShowPlugin, clickToChangePlugin} from '@brainhubeu/react-carousel';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faArrowRight, faBurst, faChevronRight, faChevronDown, faPen, faWandMagicSparkles} from '@fortawesome/free-solid-svg-icons'
import {gql} from "@apollo/client";
import ThemeSearchModalComponent from "./ThemeSearchModalComponent";
import axios from "axios";
import {MdClose} from "react-icons/md";
import {SwatchesPicker} from "react-color";
import {faTimesCircle} from "@fortawesome/free-regular-svg-icons";
import ConfettiComponent from "./ConfettiComponent";

const fontSizes = Array.from({length: 80}, (_, i) => i + 21).map(n => ({
    value: n,
    label: n
}))


class EditWallDesignComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            uuid: this.props.match.params.uuid,
            initialised: false,
            errors: "",
            wallSelectionModalOpen: false,
            tagsFetched: false,
            confettiPaletteActive: false,
            reloadTrigger: false,
            firstLoad: true,
        }


        this.handleTitleChange = this.handleTitleChange.bind(this);
        this.onFontSizeChange = this.onFontSizeChange.bind(this);
        this.onFontChange = this.onFontChange.bind(this);
        this.onFontColourChange = this.onFontColourChange.bind(this);
        this.handleCentreTextChange = this.handleCentreTextChange.bind(this);
        this.handleTransparencyChange = this.handleTransparencyChange.bind(this);
        this.onEmojiClick = this.onEmojiClick.bind(this);
        this.onConfettiPaletteActivate = this.onConfettiPaletteActivate.bind(this);
        this.onConfettiPaletteDeactivate = this.onConfettiPaletteDeactivate.bind(this);
        this.confettiColorSelected = this.confettiColorSelected.bind(this);
        this.confettiColorRemoved = this.confettiColorRemoved.bind(this);
        this.confettiPreview = this.confettiPreview.bind(this);
        this.handleCustomiseChange = this.handleCustomiseChange.bind(this);
        this.handleBackgroundSelectChange = this.handleBackgroundSelectChange.bind(this);
        this.fetchTagOptions = this.fetchTagOptions.bind(this);
        this.fetchWall = this.fetchWall.bind(this);
    }

    confettiPreview() {

        this.setState({firstLoad: false})
        let currentTrigger = this.state.reloadTrigger
        this.setState({reloadTrigger: !currentTrigger})
    }

    onConfettiPaletteActivate() {
        this.setState({confettiPaletteActive: true})
    }

    onConfettiPaletteDeactivate() {
        this.setState({confettiPaletteActive: false})
    }

    confettiColorSelected(e) {

        let confettiColors = this.props.confettiColorsAndIds
        if (confettiColors.length > 0) {
            let lastId = confettiColors[confettiColors.length - 1]["id"]
            confettiColors.push({"id": lastId + 1, "hex": e.hex})
        } else {
            confettiColors.push({"id": 0, "hex": e.hex})
        }

        this.props.dispatch(updateConfettiColors({
                confettiColorsAndIds: confettiColors
            }
        ))
        this.setState({})
    }

    confettiColorRemoved(id) {

        let confettiColors = this.props.confettiColorsAndIds


        for (var i = 0; i < confettiColors.length; i++) {
            if (confettiColors[i].id === id) {
                confettiColors.splice(i, 1)
            }
        }

        this.props.dispatch(updateConfettiColors({
                confettiColorsAndIds: confettiColors
            }
        ))

        this.setState({})
    }


    fetchWall(uuid) {

        let config = {
            headers: {
                Authorization: `Bearer ${this.props.token}`,
                "Access-Control-Allow-Origin": "*"
            }
        }

        axios.get(`${process.env.REACT_APP_SERVER_URL}getWallByUuid/` + uuid,
            config).then(result => {

            let wall = result.data

            let confettiColorsAndIds = assignConfettiColorsIds(wall.confettiColorsList)

            this.props.dispatch(updateEditingWall({
                    deliveryDate: wall.deliveryDate,
                    deliveryTime: wall.deliveryTime,
                    font: wall.font,
                    fontColour: wall.fontColour,
                    fontSize: wall.fontSize,
                    isActive: wall.active,
                    isCentred: wall.centred,
                    isDraft: wall.draft,
                    isPaid: wall.paid,
                    isPremium: wall.premium,
                    isSent: wall.sent,
                    isTransparent: wall.transparent,
                    recipientList: wall.recipientList,
                    selectionStart: 0,
                    selectionEnd: 0,
                    wallTitle: wall.text,
                    s3Id: wall.s3Id,
                    s3Path: wall.s3Path,
                    uuid: wall.uuid,
                    isConfetti: wall.confetti,
                    confettiColorsAndIds: confettiColorsAndIds
                }
            ))

        }).catch(err => {
            this.setState({errors: err.toString()})
        })
    }

    onEmojiClick(e) {
        let wallTitle = this.props.wallTitle
        let selectionStart = this.props.selectionStart
        let selectionEnd = this.props.selectionEnd
        wallTitle = wallTitle.substring(0, selectionStart) +
            e.target.textContent +
            wallTitle.substring(selectionEnd)
        selectionStart = selectionStart + e.target.textContent.length
        selectionEnd = selectionEnd + e.target.textContent.length

        this.props.dispatch(updateWallTitle({
                wallTitle: wallTitle,
                selectionStart: selectionStart,
                selectionEnd: selectionEnd
            }
        ))
    }

    onFontSizeChange = (e) => {
        this.props.dispatch(updateFontSize({
                fontSize: e.value
            }
        ))
    }

    onFontChange = (e) => {
        this.props.dispatch(updateFont({
                font: e.value
            }
        ))
    }

    onFontColourChange = (colour) => {
        this.props.dispatch(updateFontColour({
                fontColour: colour.value
            }
        ))
    };

    handleBackgroundSelectChange(isOpen){
        this.setState({wallSelectionModalOpen: isOpen})
    }

    handleTitleChange(event) {

        event.preventDefault();

        this.props.dispatch(updateWallTitle({
                wallTitle: event.target.value,
                selectionStart: event.target.selectionStart,
                selectionEnd: event.target.selectionEnd,
            }
        ))
    }

    handleCentreTextChange(event) {
        this.props.dispatch(updateIsCentred({
                isCentred: event.target.checked
            }
        ))
    }

    handleTransparencyChange(event) {
        this.props.dispatch(updateIsTransparent({
                isTransparent: event.target.checked
            }
        ))
    }

    handleConfettiChange(event) {
        this.props.dispatch(updateIsConfetti({
                isConfetti: event.target.checked
            }
        ))
    }

    handleCustomiseChange(isCustomise) {
        this.props.dispatch(updateIsCustomise({
                isCustomise: isCustomise
            }
        ))
    }

    fetchTagOptions() {

        let config = {
            headers: {
                Authorization: `Bearer ${this.props.token}`,
                "Access-Control-Allow-Origin": "*"
            }
        }

        axios.get(`${process.env.REACT_APP_SERVER_URL}tags/all`, config).then(result => {

                let mapped_tags = result.data.map((obj) => {
                    return {"value": obj["id"], "label": obj["name"]}})
                this.props.dispatch(populateTagOptions({options: mapped_tags}))
            }
        ).catch(err => {
        }).finally(result => {this.setState({tagsFetched: true})})
    }

    calculateS3Path(){

        let backgroundPath = this.props.s3Path !==null? this.props.s3Path: img103
        return backgroundPath
    }

    render() {

        this.props.updateCurrentBreadcrumb("DesignWall")

        let buttonStyle = {
            boxShadow: "0px 2px 5px -1px #333",
            float: "right",
            backgroundColor: " #8639F1",
            borderColor: " #8639F1",
            width: "100%"
        }

        if (this.props.token !== undefined &&
            this.props.user !== undefined &&
            this.props.uuid === null &&
            this.state.tagsFetched === false

        ) {
            this.fetchWall(this.state.uuid)
        }

        if (this.props.token !== undefined &&
            this.props.user !== undefined &&
            (this.state.tagsFetched === false || (this.props.tagOptions !== undefined && this.props.tagOptions.length === 0))) {
            this.fetchTagOptions()
        }

        if (this.state.errors !== "") {
            return (<div>
                <br></br>
                <Row>
                    <h6>Server Error</h6>
                    <p>{this.state.errors}</p>
                </Row>
            </div>)
        }

        if (this.props.uuid === null || this.state.tagsFetched === false) {

            return (<div>
                <br></br>
                <Row>
                    <h6>Loading</h6>
                </Row>
            </div>)

        } else {

            let backgroundPath = this.calculateS3Path()

            let confettiPaletteActive = this.state.confettiPaletteActive
            let confettiColors = this.props.confettiColorsAndIds

            let reloadTrigger = this.state.reloadTrigger
            let firstLoad = this.state.firstLoad
            return (
                <div>
                    <br></br>
                    <Modal show={this.state.wallSelectionModalOpen}
                           onHide={() => this.handleBackgroundSelectChange(false)} size={"xl"}>
                        <ThemeSearchModalComponent
                            dispatch={this.props.dispatch}
                            searchTags={this.props.searchTags}
                            searchResults={this.props.searchResults}
                            tagOptions={this.props.tagOptions}
                            previouslySelected={this.props.previouslySelected}
                            buttonStyle={buttonStyle}
                            token={this.props.token}
                            handleBackgroundSelectChange={this.handleBackgroundSelectChange}
                            isCreate={false}>
                        </ThemeSearchModalComponent>
                    </Modal>
                    <Row>
                        <Col sm={4}>
                            <h1> Edit Wall</h1>
                        </Col>
                        <Col sm={4}></Col>
                        <Col sm={4} className="right">
                            <div style={{textAlign: "right"}}>
                            <a className = {"WowPatternLink"} href="https://www.wowpatterns.com">Designed by WowPatterns</a>
                            </div>
                            </Col>
                    </Row>
                    <br></br>
                    <Row>
                        <Col sm={3}>
                            <div>
                                <Button
                                    onClick={() => this.handleBackgroundSelectChange(true)}
                                    style={buttonStyle}>
                                    Change Wall <FontAwesomeIcon icon={faWandMagicSparkles}/>
                                </Button>
                            </div>
                            <br></br>
                        </Col>
                        <Col sm={3}>
                            {(this.props.isCustomise === false || this.props.isCustomise === undefined) &&
                            <div>
                                <Button
                                    onClick={() => this.handleCustomiseChange(true)}
                                    style={buttonStyle}>
                                    Customise <FontAwesomeIcon icon={faPen}/> <span/><FontAwesomeIcon
                                    icon={faChevronRight}/>
                                </Button>
                            </div>
                            }
                            {this.props.isCustomise === true &&
                            <Button
                                onClick={() => this.handleCustomiseChange(false)}
                                style={buttonStyle}>
                                Customise <FontAwesomeIcon icon={faPen}/> <span/><FontAwesomeIcon icon={faChevronDown}/>
                            </Button>
                            }
                            <br></br>
                        </Col>
                        <Col sm={2}>
                            <Link to={"/editwall/scheduledelivery/" + this.props.uuid}>
                                <Button
                                    style={buttonStyle}>
                                    Edit Delivery <FontAwesomeIcon icon={faArrowRight}/>
                                </Button>
                            </Link>
                            <br></br>
                        </Col>
                    </Row>
                    <br/>
                    {this.props.isCustomise === true &&
                    <div>
                        <Row>
                            <Col sm={3}>
                                <div title="Font">
                                    <Select
                                        style={{display: "block", margin: "0 auto", width: "100%"}}
                                        options={fonts}
                                        value={fonts.filter(x => x.value === this.props.font)[0]}
                                        onChange={this.onFontChange}/>
                                </div>
                                <br/>
                            </Col>
                            <Col sm={1}>
                                <div title="Font Colour">
                                    <Select placeholder="font color"
                                            options={fontColours}
                                            value={fontColours.filter(x => x.value === this.props.fontColour)[0]}
                                            onChange={this.onFontColourChange}
                                    />
                                </div>
                                <br/>
                            </Col>
                            <Col sm={1}>
                                <div title="Font Size">
                                    <Select style={{display: "block", margin: "0 auto", width: "100%"}}
                                            options={fontSizes}
                                            value={fontSizes.filter(x => x.value === this.props.fontSize)[0]}
                                            onChange={this.onFontSizeChange}/>
                                </div>
                                <br></br>
                            </Col>
                            <Col sm={1}>
                                <Dropdown autoClose="outside">
                                    <Dropdown.Toggle>
                                        😁
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <Dropdown.Item><Picker onEmojiClick={this.onEmojiClick}
                                                               native={true}/></Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Col>
                            <Col sm={2}>
                                <Form>
                                    <div className="checkboxes">
                                        <Form>
                                            <Form.Check
                                                className={"premiumToggle"}
                                                type="switch"
                                                checked={this.props.isCentred}
                                                id="center-switch"
                                                label="Center text"
                                                onChange={(e) => this.handleCentreTextChange(e)}
                                            />
                                        </Form>
                                    </div>
                                </Form>
                            </Col>
                            <Col sm={2}>
                                <Form>
                                    <div className="checkboxes">
                                        <Form>
                                            <Form.Check
                                                className={"premiumToggle"}
                                                type="switch"
                                                checked={this.props.isTransparent}
                                                id="transparent-switch"
                                                label="Transparent box"
                                                onChange={(e) => this.handleTransparencyChange(e)}
                                            />
                                        </Form>
                                    </div>
                                </Form>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={3}>
                                <Form>
                                    <div className="checkboxes">
                                        <Form>
                                            <Form.Check
                                                className={"premiumToggle"}
                                                type="switch"
                                                checked={this.props.isConfetti}
                                                id="transparent-switch"
                                                label="Confetti"
                                                onChange={(e) => this.handleConfettiChange(e)}
                                            />
                                        </Form>
                                    </div>
                                </Form>
                            </Col>
                            <Col sm={2}>
                                {this.props.isConfetti &&
                                <div>
                                    {confettiPaletteActive === false &&
                                    <div>
                                        <Button
                                            style={buttonStyle}
                                            onClick={this.onConfettiPaletteActivate}
                                        >Confetti Colors <FontAwesomeIcon icon={faChevronRight}/>
                                        </Button>
                                    </div>}
                                    {confettiPaletteActive === true &&
                                    <div>
                                        <div>
                                            <Button
                                                style={buttonStyle}
                                                onClick={this.onConfettiPaletteDeactivate}
                                            >Close Palette <FontAwesomeIcon icon={faTimesCircle}/>
                                            </Button>
                                        </div>
                                        <div style={{position: "absolute", marginTop: "50px", zIndex: 1}}>
                                            <SwatchesPicker
                                                onChange={(e) => this.confettiColorSelected(e)}/>
                                        </div>
                                    </div>}
                                </div>}
                            </Col>
                            <Col sm={3}>
                                {this.props.isConfetti && confettiColors && confettiColors.map((colour, index) => (
                                    <span style={{float: "left",}}>
                            <div style={{
                                height: "20px",
                                width: "20px",
                                backgroundColor: colour.hex,
                                float: "left"
                            }}></div>
                            <i> <MdClose onClick={(e) => this.confettiColorRemoved(colour.id)}></MdClose> </i>
                            </span>
                                ))}
                            </Col>
                            <Col sm={2}>
                                {this.props.isConfetti &&
                                <div>
                                    <Button
                                        style={buttonStyle}
                                        onClick={this.confettiPreview}
                                    >Preview <FontAwesomeIcon icon={faBurst}/>
                                    </Button>
                                </div>}
                            </Col>
                        </Row>
                        <br/>
                    </div>
                    }
                    <Row>
                        {!firstLoad && reloadTrigger &&
                        <div><ConfettiComponent colors={extractConfettiColorsOnly(this.props.confettiColorsAndIds)}
                        ></ConfettiComponent></div>}
                        {!firstLoad && !reloadTrigger &&
                        <div><ConfettiComponent colors={extractConfettiColorsOnly(this.props.confettiColorsAndIds)}
                        ></ConfettiComponent></div>}
                        <Col sm={12}>
                            <div style={{
                                paddingTop: "1px",
                                background: `url(${backgroundPath})`,
                                minHeight: "100vh",
                                minWidth: "100%"
                            }}>

                                <div style={{
                                    backgroundColor: switchTransparencyBool(this.props.isTransparent),
                                    marginLeft: "20px",
                                    marginTop: "20px",
                                    marginRight: "20px",
                                    maxWidth: "100%",
                                    minHeight: "100px",
                                    padding: "2%",
                                    borderRadius: "10px",
                                    boxShadow: noShadowIftransparent(this.props.isTransparent),
                                }}>

                                    <TextareaAutosize

                                        style={{
                                            whiteSpace: "pre-line",
                                            opacity: 1,
                                            overflow: "hidden",
                                            lineHeight: this.props.fontSize*2 + "px",
                                            backgroundColor: "rgba(0, 0, 0, 0.0)",
                                            borderColor: "rgba(0, 0, 0, 0.0)",
                                            textAlign: switchCentreBoolToTextValue(this.props.isCentred),
                                            fontSize: this.props.fontSize,
                                            boxSizing: "border-box",
                                            color: this.props.fontColour,
                                            width: "100%",
                                            fontFamily: this.props.font
                                        }}
                                        maxLength={2000}
                                        onChange={this.handleTitleChange}
                                        onClick={this.handleTitleChange}
                                        onKeyUp={this.handleTitleChange}
                                        placeholder={'Enter a title for your wall here!'}
                                        value={this.props.wallTitle}>

                                    </TextareaAutosize>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>)
        }
    }
}

function mapStateToProps(state) {

    return {
        token: state.getIn(["centralState", "accessToken"]),
        user: state.getIn(["centralState", "user"]),
        uuid: state.getIn(["centralState", "editInProgress", "uuid"]),
    }
}

export default withRouter(withAuth0(connect(mapStateToProps)(EditWallDesignComponent)));