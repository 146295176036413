import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import {Button} from "react-bootstrap";

const Logout = () => {
    const { logout } = useAuth0();

    return (
        <Button
            style={{backgroundColor: "#FFFFFF",
                borderColor: "#FFFFFF",
                color: " #8639F1",
                boxShadow: "0px 2px 5px -1px #333",
                marginRight: "20px"
                }}
            onClick={() => logout({ returnTo: window.location.origin })}>
            Log Out
        </Button>
    );
};

export default Logout;