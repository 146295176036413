import React, {Component} from "react";
import {Button, Modal} from "react-bootstrap";
import {Redirect, withRouter} from "react-router-dom";
import axios from "axios";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowRight, faExclamationTriangle} from "@fortawesome/free-solid-svg-icons";
import {withAuth0} from "@auth0/auth0-react";
import {connect} from "react-redux";


class PaymentComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            redirectToConfirmation: false,
            uuid: this.props.match.params.uuid,
            freewall: this.props.match.params.freewall,
            errors: {},
            showErrorModal: false
        }

        this.payForWall = this.payForWall.bind(this);
        this.openModal = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);

    }

    payForWall(){

        let errors = {}

        let config = {
            headers: {
                Authorization: `Bearer ${this.props.token}`,
                "Access-Control-Allow-Origin": "*"
            }
        }

        axios.get(`${process.env.REACT_APP_SERVER_URL}payment/` + this.state.uuid + '/create',
            config).then(result => {
            window.location = result.data
        }).catch(err => {
            errors = {
                "Server Error": err.toString(),
            }
            this.setState({errors: errors})
            this.openModal();
        })
    }

    activateFreeWall(){

        let errors = {}

        let config = {
            headers: {
                Authorization: `Bearer ${this.props.token}`,
                "Access-Control-Allow-Origin": "*"
            }
        }

        axios.post(`${process.env.REACT_APP_SERVER_URL}activateFreeWall/` + this.state.uuid + '/create', {},
            config).then(result => {
                this.setState({
                    redirectToConfirmation: true,
                })

        }).catch(err => {
            errors = {
                "Server Error": err.toString(),
            }
            this.setState({errors: errors})
            this.openModal();
        })

    }

    openModal() {
        this.setState({showErrorModal: true})
    }

    closeModal() {
        this.setState({showErrorModal: false})
    }

    render() {

        let errors = this.state.errors
        let errorsJsx = Object.keys(errors).map(function (key, index) {
            return (<div><h6>{key}</h6><p>{errors[key]}</p></div>)
        })


        this.props.updateCurrentBreadcrumb("Payment")

        const {redirectToConfirmation} = this.state

        if (redirectToConfirmation) {
            return (
                <Redirect to={'/createwall/confirmation/' + this.state.uuid + '/activatefreewall'}/>
            )
        }

        let freewall = this.state.freewall

        return (
            <div>
                <Modal show={this.state.showErrorModal} onHide={this.closeModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>Errors occurred <FontAwesomeIcon icon={faExclamationTriangle}/></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {errorsJsx}
                    </Modal.Body>
                </Modal>
                <br></br>
                <h6>Draft Saved!</h6>
                <br></br>
                <p>By activating your wall you agree to our <a className={"Homelinks"}
                                                               href={`${process.env.REACT_APP_UI_URL}termsandconditions`}
                                                               target="_blank">Terms of Service</a></p>
                { freewall !== "freewall" && <Button onClick={()=> this.payForWall()}
                        style={{
                            backgroundColor: " #8639F1",
                            borderColor: " #8639F1",
                            boxShadow: "0px 2px 5px -1px #333",
                            width: "40%"
                        }}>
                    Pay and activate wall so others can contribute to it <FontAwesomeIcon icon={faArrowRight}/>

                </Button> }
                { freewall === "freewall" && <Button onClick={()=> this.activateFreeWall()}
                                                                style={{
                                                                    backgroundColor: " #8639F1",
                                                                    borderColor: " #8639F1",
                                                                    boxShadow: "0px 2px 5px -1px #333",
                                                                    width: "40%"
                                                                }}>
                    Activate free wall so others can contribute to it

                </Button> }
            </div>
        )
    }
}
function mapStateToProps(state) {

    return {
        token: state.getIn(["centralState", "accessToken"]),
    }
}

export default withRouter(connect(mapStateToProps)(PaymentComponent));