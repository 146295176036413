import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import {Button} from "react-bootstrap";
import {ReactSession} from "react-client-session";

ReactSession.setStoreType("localStorage");

const Login = () => {

    const { loginWithRedirect } = useAuth0();

    return <Button
        style={{backgroundColor: "#FFFFFF",
            borderColor: "#FFFFFF",
            color: " #8639F1",
            boxShadow: "0px 2px 5px -1px #333",
            marginRight: "20px"}}
        onClick={() => {
            ReactSession.set("wallState", undefined);
            return loginWithRedirect();
        }}>Log In/Sign up</Button>;
};

export default Login;