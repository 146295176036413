import {Button, Nav, Navbar, SplitButton, Dropdown, DropdownButton} from "react-bootstrap";
import {Component} from "react";
import {Link, NavLink} from "react-router-dom";
import React from 'react';
import Login from "./LoginComponent";
import {withAuth0} from "@auth0/auth0-react";
import Logout from "./LogoutComponent";
import logo from "../images/WebLogoWithSlogan.png"
import {faArrowRight, faBars} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {isMobile} from "react-device-detect";

class Header extends Component {

    constructor(props) {
        super(props);
    }


    render() {

        const {isAuthenticated, user} = this.props.auth0;

        let authenticationButton = isAuthenticated ? <Logout/> : <Login/>;

        // previous logic to hide header if we're on "create wall"
        if ( /*window.location.pathname.toLowerCase().includes('/createwall') ||*/ window.location.pathname.toLowerCase().includes('/viewwall')) {
            return null;
        } else {
            return (
                <div>
                    <Navbar style={{backgroundColor: " #8639F1", color: "#FFFFFF"}} expand="lg">
                        <Navbar.Brand style = {{width: "50%"}}>
                            <img src={logo} style= {{width: "100%", marginTop: "5px", float: "left"}}></img>
                        </Navbar.Brand>
                        <Navbar.Collapse id="basic-navbar-nav" style={{float: "right"}}>
                            <Nav className="ml-auto">
                            </Nav>
                        </Navbar.Collapse>
                        {isAuthenticated && (<div
                            style={{
                                marginRight: "20px"
                            }}>
                            <div>You are logged in as:</div>
                            <div>{user.name}</div>
                        </div>)}
                        {authenticationButton}
                        {isAuthenticated && (<Link to="/createwall/designwall">
                            <Button variant="outline-primary"
                                    style={{
                                        backgroundColor: "#FFFFFF",
                                        borderColor: "#FFFFFF",
                                        color: " #8639F1",
                                        marginRight: "20px",
                                        boxShadow: "0px 2px 5px -1px #333"
                                    }}
                            >Create Wall</Button>
                        </Link>)}

                        {isAuthenticated && (
                            <Dropdown
                                className="WallsDropdown"
                                style={{
                                    color: " #8639F1",
                                    marginRight: "20px",
                                    boxShadow: "0px 2px 5px -1px #333"
                                }}>

                                <Dropdown.Toggle variant="success" id="dropdown-basic">
                                    Your Walls
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item as={Link} to="/draftwalls" eventKey="1">
                                        Draft Walls
                                    </Dropdown.Item>
                                    <Dropdown.Item as={Link} to="/activewalls" eventKey="2">
                                        Active Walls
                                    </Dropdown.Item>
                                    <Dropdown.Item as={Link} to="/sentwalls" eventKey="3">
                                        Sent Walls
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>)}
                        <Dropdown
                            align="end"
                            className="WallsDropdown"
                            style={{
                                color: " #8639F1",
                                marginRight: "20px",
                                boxShadow: "0px 2px 5px -1px #333"
                            }}>

                            <Dropdown.Toggle variant="success" id="dropdown-basic">
                                <FontAwesomeIcon icon={faBars}/>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item as={Link} to="/home" eventKey="1">
                                    Home
                                </Dropdown.Item>
                                <Dropdown.Item as={Link} to="/pricing" eventKey="2">
                                    Pricing
                                </Dropdown.Item>
                                <Dropdown.Item as={Link} to="/help" eventKey="3">
                                    FAQ
                                </Dropdown.Item>
                                <Dropdown.Item as={Link} to="/privacypolicy" eventKey="4">
                                    Privacy Policy
                                </Dropdown.Item>
                                <Dropdown.Item as={Link} to="/termsandconditions" eventKey="5">
                                    Terms And Conditions
                                </Dropdown.Item>


                            </Dropdown.Menu>
                        </Dropdown>
                    </Navbar>
                    <br/>
                </div>)
        }
    };
}

export default withAuth0(Header);