import {Col, Nav, Navbar, NavDropdown, Button, Modal} from "react-bootstrap";
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import React from 'react';
import './App.css';
import CreateWallComponent from "./components/CreateWallComponent"
import Header from "./components/Header"
import {Component} from "react";
import ViewWallComponent from "./components/ViewWallComponent";
import HomeComponent from "./components/HomeComponent";
import CookieConsent, { Cookies } from "react-cookie-consent";
import {connect} from "react-redux";
import {
    setAccessToken, setUser
} from './actions/index';
import {withAuth0} from "@auth0/auth0-react";
import {ApolloClient, createHttpLink, gql, InMemoryCache} from "@apollo/client";
import {setContext} from "@apollo/client/link/context";
import axios from "axios";
import EditWallComponent from "./components/EditWallComponent";
import WallsComponent from "./components/WallsComponent";
import HelpComponent from "./components/HelpComponent";
import PricingComponent from "./components/PricingComponent";
import {BrowserView, MobileView, isMobile} from 'react-device-detect';
import PrivacyPolicyComponent from "./components/PrivacyPolicyComponent";
import TermsAndConditions from "./components/TermsAndConditions";
import Footer from "./components/Footer";

class App extends Component {

    constructor(props) {
        super(props);
        this.state = {
            fetchingToken: false,
            userInitialised: false,
        }
    }

    createOrUpdateUser() {

        if (this.state.userInitialised === false) {

            this.setState({userInitialised:true})

            let config = {
                headers: {
                    Authorization: `Bearer ${this.props.token}`,
                    "Access-Control-Allow-Origin": "*"
                }
            }

            axios.post(`${process.env.REACT_APP_SERVER_URL}createOrUpdateAppUserAuth0`,
                {},
                config).then(result => {
            }).catch(err => {
                console.log(`Something went wrong ${err}`);
            })
        }
    }

    render() {

        const {isAuthenticated, getAccessTokenSilently, user} = this.props.auth0;

        if ((this.props.token === "" || this.props.token === undefined) && isAuthenticated) {
            getAccessTokenSilently({
                audience: `https://${process.env.REACT_APP_AUTH0_DOMAIN}/api/v2/`,
            }).then((token) => {
                this.props.dispatch(
                    setAccessToken({accessToken: token}))
                this.props.dispatch(
                    setUser({user: user}))
                this.createOrUpdateUser()
            });
        }


        return (
            <main>
                <CookieConsent
                    location="bottom"
                    buttonText="Accept"
                    cookieName="WallFromUsCookieAcceptance"
                    style={{ background: "#2B373B" }}
                    buttonStyle={{ borderRadius: "5px", background: "#FFFFFF", fontSize: "13px" }}
                    expires={150}
                >
                    We'd like to use cookies to make your experience better and also to make our site work. <a style={{color:"#8639F1" }}href={`${process.env.REACT_APP_UI_URL}privacypolicy`}>See Our Privacy Policy</a>
                </CookieConsent>
                <Header/>
                <Switch>
                    <Route path='/home' component={HomeComponent}/>
                    <Route path='/createwall' component={CreateWallComponent}/>
                    <Route path='/activewalls'
                           render={(props) => (
                               <WallsComponent wallType={"active"} key={0}/>
                           )}
                    />
                    <Route path='/draftwalls'
                           render={(props) => (
                               <WallsComponent wallType={"draft"} key={1}/>
                           )}
                    />
                    <Route path='/sentwalls'
                           render={(props) => (
                               <WallsComponent wallType={"sent"} key={2}/>
                           )}
                    />
                    <Route path='/editwall/' component={EditWallComponent}/>
                    <Route path='/help' component={HelpComponent}/>
                    <Route path='/pricing' component={PricingComponent}/>
                    <Route path='/privacypolicy' component={PrivacyPolicyComponent}/>
                    <Route path='/termsandconditions' component={TermsAndConditions}/>
                    <Route path='/viewwall/:uuid' component={ViewWallComponent}/>
                    <Route path='/' component={HomeComponent}/>
                </Switch>
                <Footer/>

            </main>
        );
    }
}

function mapDispatchToProps(dispatch) {

    return {
        setAccessToken: (x) => dispatch(setAccessToken(x)),
        setUser: (x) => dispatch(setUser(x)),
        dispatch
    }
}

function mapStateToProps(state) {

    return {
        token: state.getIn(["centralState", "accessToken"]),
    }
}

export default withAuth0(connect(mapStateToProps, mapDispatchToProps)(App))
