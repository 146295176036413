import React, {Component} from 'react';
import {Container, Col, Row, Button} from "react-bootstrap";
import backgroundTriangle from "../images/BackgroundTriangle4.png"
import LeavingCard from "../images/LeavingCard.png"
import BirthdayCard from "../images/BirthdayCard.png"
import ThankYouCard from "../images/ThankYouCard.png"
import LadyUser from "../images/ladyuser.jpeg"
import './HomeComponent.css'
import {faCheck} from '@fortawesome/free-solid-svg-icons'

import {WebSocketLink} from '@apollo/client/link/ws';
import {split, HttpLink, gql, useSubscription} from '@apollo/client';
import {getMainDefinition} from '@apollo/client/utilities';
import {ApolloClient, InMemoryCache, ApolloProvider} from '@apollo/client';
import {faArrowRight, faExclamationTriangle, faPen, faRocket, faShareSquare, faStar} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEnvelope} from "@fortawesome/free-regular-svg-icons";
import {faPinterest, faFacebook, faInstagram, faTwitter, faYoutube} from "@fortawesome/free-brands-svg-icons";
import ReactPlayer from 'react-player'
import {useAuth0, withAuth0} from "@auth0/auth0-react";
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import {toast, ToastContainer} from "react-toastify";
import axios from "axios";

// const httpLink = new HttpLink({
//     uri: '${process.env.REACT_APP_SERVER_URL}graphql'
// });

// const wsLink = new WebSocketLink({
//     uri: 'ws://localhost:8080/subscriptions',
//     options: {
//         reconnect: true
//     }
// });

// The split function takes three parameters:
//
// * A function that's called for each operation to execute
// * The Link to use for an operation if the function returns a "truthy" value
// * The Link to use for an operation if the function returns a "falsy" value
// const splitLink = split(
//     ({ query }) => {
//         const definition = getMainDefinition(query);
//         return (
//             definition.kind === 'OperationDefinition' &&
//             definition.operation === 'subscription'
//         );
//     },
//     wsLink,
//     httpLink,
// );

// const client = new ApolloClient({
//     link: splitLink,
//     cache: new InMemoryCache()
// });

// const COMMENTS_SUBSCRIPTION = gql`
//     subscription {
//         stockPrice(symbol:"GOOG") {
//             price
//             symbol
//             timestamp
//         }
//     }
// `;

// function LatestComment() {
//     const {data, loading} = useSubscription(
//         COMMENTS_SUBSCRIPTION,
//         {variables: {}}
//     );
//
//     var res = !loading && data
//     return <h4>New price: {JSON.stringify(res)}</h4>;
// }

class HomeComponent extends Component {

    constructor(props) {
        super(props);

        this.state = {
            playing: false
        }
        this.userInitialised = false

        this.handleMarketingPreferences = this.handleMarketingPreferences.bind(this);
    }

    componentDidMount() {
        this.setState({
            playing: true
        })
    }

    marketingPreferenceUpdate(value) {

        if (value !== null) {

            let config = {
                headers: {
                    Authorization: `Bearer ${this.props.token}`,
                    "Access-Control-Allow-Origin": "*"
                }
            }
            axios.post(`${process.env.REACT_APP_SERVER_URL}updatedMarketingPreferencesForUser/` + value
                , null, config).then(result => {
            }).catch(err => {
            })
        }
        toast.dismiss();
    }

    handleMarketingPreferences() {

        let config = {
            headers: {
                Authorization: `Bearer ${this.props.token}`,
                "Access-Control-Allow-Origin": "*"
            }
        }

        this.userInitialised = true

        axios.get(`${process.env.REACT_APP_SERVER_URL}getMarketingPreferencesForUser`,
            config).then(result => {
            if (result.data === "") {
                let buttonStyle = {marginRight: "10px", color: "#8639F1", boxShadow: "0px 2px 5px -1px #333"}
                const toastMessage = (
                    <div>
                        <h5>Would you like to join our marketing mailing list?</h5>
                        <p>This will occasionally notify you of promotions and new features from us.</p>
                        <Button variant="light" style={buttonStyle}
                                onClick={() => this.marketingPreferenceUpdate(true)}>
                            Yes</Button>
                        <Button variant="light" style={buttonStyle}
                                onClick={() => this.marketingPreferenceUpdate(null)}>
                            Maybe later</Button>
                        <Button variant="light" style={buttonStyle}
                                onClick={() => this.marketingPreferenceUpdate(false)}>
                            No</Button>
                    </div>
                )

                toast(({closeToast}) => toastMessage,
                    {
                        position: "bottom-right",
                        autoClose: false,
                        hideProgressBar: true,
                        closeOnClick: true,
                        draggable: true,
                        theme: "dark",
                    })
            }
        }).catch(err => {
        })
    }


    render() {

        const {loginWithRedirect, isAuthenticated} = this.props.auth0;

        let user_direction = <p><strong><u onClick={() => {
            return loginWithRedirect()
        }}><a className={"WowPatternLink"} href="">
            Click here</a></u> to sign up and create a draft wall!</strong></p>


        if (isAuthenticated)
            user_direction = <Link to="/createwall/designwall">
                <Button variant="outline-primary"
                        style={{
                            width: "70%",
                            backgroundColor: "#8639F1",
                            borderColor: "#8639F1",
                            color: " #FFFFFF",
                            marginRight: "20px",
                            boxShadow: "0px 2px 5px -1px #333"
                        }}
                ><strong>Click here to create a draft wall! </strong><FontAwesomeIcon icon={faArrowRight}/></Button>
            </Link>

        if (this.userInitialised === false && this.props.token !== undefined && this.props.token !== '') {
            this.handleMarketingPreferences();
        }

        let sampleStyles = {width: "90%", margin: "auto", display: "block", boxShadow: "0px 2px 5px -1px #333"}

        let line =                     <hr
            style={{
                color: "#ABC",
                backgroundColor: "#ABC",
                height: 2,
                width: "90%",
                margin: "auto",
            }}
        />


        let socialMediaIcons = [
            {"icon": faFacebook, "href": "https://www.facebook.com/people/Wall-From-Us/100090452221455/"},
            {"icon": faInstagram, "href": "https://www.instagram.com/wallfromus/"},
            {"icon": faTwitter, "href": "https://twitter.com/WallFromUs"},
            {"icon": faYoutube, "href": "https://www.youtube.com/@wallfromus"},
            {"icon": faPinterest, "href": "https://www.pinterest.co.uk/wallfromus/"}]

        return (
            <div>
                <ToastContainer toastStyle={{backgroundColor: "#8639F1"}}></ToastContainer>

                <Container fluid>
                    <Row>
                        <Col sm={1}>
                        </Col>
                        <Col sm={4}>
                            <br></br>
                            <div style={{textAlign: 'center'}}>
                                <h3>Welcome!</h3>
                                <br></br>
                                <h5><strong>Wall From Us</strong> is a <strong>free</strong> and fun to use group greeting ecard
                                    service.</h5>
                                <p>For more information, please read about what's included and how it works below, and check out our
                                    awesome sample walls.</p>
                                {user_direction}
                            </div>

                        </Col>
                        <Col sm={1}>
                        </Col>
                        <Col sm={6}>
                            <ReactPlayer url='video/WallFromUsMiniClip.mp4'
                                         loop={true}
                                         width={"100%"}
                                         volume={0}
                                         controls={true}
                                         playing={this.state.playing}/>
                        </Col>


                    </Row>
                    <br></br>
                    {line}
                    <Row>
                    </Row>
                    <Row>

                        <h3 style={{textAlign: 'center', margin: '20px'}}>View our sample
                            walls</h3>
                    </Row>
                    <Row>
                        <Col sm={4}>
                            <h5 style={{textAlign: 'center', marginTop: '10px'}}>Leaving Wall</h5>
                            <a href={`${process.env.REACT_APP_UI_URL}viewwall/da9b0e61-83b4-402a-827c-071dab67530a-1568516856?delivery=true`}
                              title = "Click to open Wall" target="_blank" alt={"Leaving Free Group Greeting ECard"}>
                                <ReactPlayer url='video/DuncanVid2.mp4'
                                             loop={true}
                                             volume={0}
                                             width={"100%"}
                                             playing={this.state.playing}
                                             />
                            </a>

                        </Col>
                        <Col sm={4}>
                            <h5 style={{textAlign: 'center', marginTop: '10px'}}>Birthday Wall (with Confetti)</h5>
                            <a href={`${process.env.REACT_APP_UI_URL}viewwall/77831400-3bb3-402f-b2dc-892c3d28a1a0-1351140328?delivery=true`}
                               title = "Click to open Wall" target="_blank" alt={"Birthday Free Group Greeting ECard"}>
                                    <ReactPlayer url='video/LisaVid2.mp4'
                                                 loop={true}
                                                 volume={0}
                                                 width={"100%"}
                                                 playing={this.state.playing}
                                                 />
                            </a>

                        </Col>
                        <Col sm={4}>
                            <h5 style={{textAlign: 'center', marginTop: '10px'}}>Thank You Wall (with Confetti)</h5>
                            <a href={`${process.env.REACT_APP_UI_URL}viewwall/045da4cf-3736-431a-9d0c-d9bf0305c613-1309099327?delivery=true`}
                               title = "Click to open Wall" target="_blank" alt={"Farewell Free Group Greeting ECard"}>
                                <ReactPlayer url='video/AliVid2.mp4'
                                             loop={true}
                                             volume={0}
                                             width={"100%"}
                                             playing={this.state.playing}
                                             />
                            </a>
                        </Col>
                    </Row>
                    <br></br>
                    {line}
                    <Row>
                        <h3 style={{textAlign: 'center', margin: '20px', marginBottom: '50px'}}>What is included</h3>
                        <br></br>
                        <br></br>
                        <Col sm={2}>
                        </Col>
                        <Col sm={4}>
                            <p><FontAwesomeIcon style={{fontSize: 18, color: "#32CD32"}} icon={faCheck}/> <strong>Up to 100 contributors per wall</strong></p>
                            <p><FontAwesomeIcon style={{fontSize: 18, color: "#32CD32"}} icon={faCheck}/> <strong>Wall downloading</strong></p>
                            <p><FontAwesomeIcon style={{fontSize: 18, color: "#32CD32"}} icon={faCheck}/> <strong>Sending via e-mail to up to 10 recipients</strong></p>
                            <p><FontAwesomeIcon style={{fontSize: 18, color: "#32CD32"}} icon={faCheck}/> <strong>Sending at a specified date and time</strong></p>
                            <p><FontAwesomeIcon style={{fontSize: 18, color: "#32CD32"}} icon={faCheck}/> <strong>Include images in posts</strong></p>
                            <p><FontAwesomeIcon style={{fontSize: 18, color: "#32CD32"}} icon={faCheck}/> <strong>Access to millions of GIFs from Giphy</strong></p>

                        </Col>
                        <Col sm={1}>
                        </Col>
                        <Col sm={4}>
                            <p><FontAwesomeIcon style={{fontSize: 18, color: "#32CD32"}} icon={faCheck}/> <strong>Permanent hosting of your wall</strong></p>
                            <p><FontAwesomeIcon style={{fontSize: 18, color: "#32CD32"}} icon={faCheck}/> <strong>Access to over 100 background images</strong></p>
                            <p><FontAwesomeIcon style={{fontSize: 18, color: "#32CD32"}} icon={faCheck}/> <strong>Mobile and tablet friendly viewing</strong></p>
                            <p><FontAwesomeIcon style={{fontSize: 18, color: "#32CD32"}} icon={faCheck}/> <strong>No sign up required for contributors</strong></p>
                            <p><FontAwesomeIcon style={{fontSize: 18, color: "#32CD32"}} icon={faCheck}/> <strong>NEW! Add confetti to your wall</strong></p>
                            <p><FontAwesomeIcon style={{fontSize: 18, color: "#FFD700"}} icon={faStar}/> <strong>No ads (premium cards only)</strong></p>
                        </Col>
                        <Col sm={1}>
                        </Col>
                    </Row>
                    <br></br>
                    {line}
                    <Row>
                        <h3 style={{textAlign: 'center', margin: '20px'}}>How it works</h3>
                    </Row>
                    <br></br>
                    <br></br>
                    <Row>
                        <Col sm={3}>
                            <div style={{
                                paddingTop: "1px",
                                background: `url(${backgroundTriangle})`,
                                minHeight: "300px",
                                backgroundRepeat: "no-repeat",
                                backgroundPosition: "top center",
                            }}>
                                <h5 style={{textAlign: 'center'}}>Create <FontAwesomeIcon icon={faPen}/></h5>
                                <br></br>
                                <p style={{width: '100%', textAlign: 'center'}}>Log in, pick a background, title and
                                    more! Then set a delivery time and recipients.</p>
                            </div>
                        </Col>
                        <Col sm={3}>
                            <div style={{
                                paddingTop: "1px",
                                background: `url(${backgroundTriangle})`,
                                minHeight: "300px",
                                backgroundRepeat: "no-repeat",
                                backgroundPosition: "top center",
                            }}>
                                <h5 style={{textAlign: 'center'}}>Activate <FontAwesomeIcon icon={faRocket}/></h5>
                                <br></br>
                                <p style={{width: '100%', textAlign: 'center'}}>Pay and make your wall public, or save a
                                    draft to come back to if you're not ready yet.</p>
                            </div>
                        </Col>
                        <Col sm={3}>
                            <div style={{
                                paddingTop: "1px",
                                background: `url(${backgroundTriangle})`,
                                minHeight: "300px",
                                backgroundRepeat: "no-repeat",
                                backgroundPosition: "top center",
                            }}>
                                <h5 style={{textAlign: 'center'}}>Share <FontAwesomeIcon icon={faShareSquare}/></h5>
                                <br></br>
                                <p style={{width: '100%', textAlign: 'center'}}>Share your wall's link with friends so
                                    they can contribute. Up to 100 friends can contribute images, GIFs and messages to
                                    your wall.</p>
                            </div>
                        </Col>
                        <Col sm={3}>
                            <div style={{
                                paddingTop: "1px",
                                background: `url(${backgroundTriangle})`,
                                minHeight: "300px",
                                backgroundRepeat: "no-repeat",
                                backgroundPosition: "top center",
                            }}>
                                <h5 style={{textAlign: 'center'}}>Send <FontAwesomeIcon icon={faEnvelope}/></h5>
                                <br></br>
                                <p style={{width: '100%', textAlign: 'center'}}>Receive notifications to your email
                                    address when the link to your wall has been sent. You can send to up to 10
                                    recipients!</p>
                            </div>
                        </Col>
                    </Row>
                    <br></br>
                    {line}
                    <br></br>
                    <Row>
                        <Col sm={8}>
                            <img src={LadyUser} style = {{width: "100%", boxShadow: "\"0px 2px 5px -1px #333\""}}>

                            </img>
                        </Col>
                        <Col sm={4} style={{textAlign: 'center'}}>
                            <h3 style={{textAlign: 'center', margin: '20px'}}>Want to know more?</h3>
                            <p>Just click below to find out about </p>
                            <p><a className={"Homelinks"} href={`${process.env.REACT_APP_UI_URL}pricing`}>Pricing</a>
                            </p>
                            <p><a className={"Homelinks"} href={`${process.env.REACT_APP_UI_URL}help`}>FAQ</a></p>
                            <p><a className={"Homelinks"} href={`${process.env.REACT_APP_UI_URL}termsandconditions`}>Terms
                                and Conditions</a></p>
                            <p><a className={"Homelinks"} href={`${process.env.REACT_APP_UI_URL}privacypolicy`}>Privacy
                                Policy</a></p>
                            <p>
                                {
                                    socialMediaIcons.map((smObject, i) =>
                                        <a href={smObject.href} target="_blank"><FontAwesomeIcon icon={smObject.icon}
                                                                    size = "xl"
                                                                    style={{"marginRight": "15px",
                                                                            "color": "#000000"}}
                                                                    /></a>
                                    )
                                }
                            </p>
                        </Col>

                    </Row>
                    <br></br>
                    {line}
                    <br></br>
                <Row>
                    <Col sm={3}>

                    </Col>
                    <Col sm={3}>

                    </Col>
                    <Col sm={3}>

                    </Col>
                    <Col sm={3}>

                    </Col>
                </Row>

                </Container>

                {/*<ApolloProvider client={client}>*/}
                {/*<Container fluid>*/}
                {/*    <LatestComment/>*/}
                {/*</Container>*/}
                {/*</ApolloProvider>*/}
            </div>)
    }
}

function mapStateToProps(state) {

    return {
        user: state.getIn(["centralState", "user"]),
        token: state.getIn(["centralState", "accessToken"]),
    }
}

export default withAuth0(connect(mapStateToProps)(HomeComponent));