import React, {Component} from 'react';
import {Container, Col, Row, InputGroup, FormControl, Accordion, Card, Button} from "react-bootstrap";
import {withAuth0} from "@auth0/auth0-react";

class PrivacyPolicyComponent extends Component {

    constructor(props) {
        super(props);

    }

    render() {

        const {isAuthenticated} = this.props.auth0;

        return (
            <div>
                <Container fluid>
                    <Row>
                        <Col sm={1}></Col>
                        <Col sm={10}>
                            <h2>Wall From Us Group Ecards Privacy Policy</h2>

                            <p>This Privacy Policy describes how your personal information is collected, used, and
                                shared when you visit or make a purchase from wallfromus.com (the “Site”).</p>
                            <p>Topics:
                                <ul>

                                    <li>What data do we collect and how?</li>
                                    <li>How do we use your data?</li>
                                    <li>How do we store your data?</li>
                                    <li>Your rights</li>
                                    <li>Marketing</li>
                                    <li>Privacy policies of other websites</li>
                                    <li>Changes to our privacy policy</li>
                                    <li>How to contact us</li>
                                </ul>
                            </p>
                            <h4>What data do we collect and how?</h4>
                            <p>When you visit the Site, we automatically collect certain information about your device,
                                including information about your web browser, IP address, time zone, and some of the
                                cookies that are installed on your device. Additionally, as you browse the Site, we
                                collect information about the individual web pages or products that you view, what
                                websites or search terms referred you to the Site, and information about how you
                                interact with the Site. We refer to this automatically-collected information as “Device
                                Information.”</p>
                            <p>We collect Device Information using the following technologies:
                                <ul>
                                    <li>“Cookies” are data files that are placed on your device or computer and often
                                        include an anonymous unique identifier. For more information about cookies, and
                                        how to disable cookies, read the below section and visit <a
                                            href={"http://www.allaboutcookies.org"}>www.allaboutcookies.org</a>.
                                    </li>
                                    <li>“Log files” track actions occurring on the Site, and collect data including your
                                        IP address, browser type, Internet service provider, referring/exit pages, and
                                        date/time stamps.
                                    </li>
                                </ul></p>

                            <p>Additionally when you make a purchase or attempt to make a purchase through the Site, we
                                collect certain information from you, including your name, billing address, shipping
                                address, payment information (including credit card numbers, email address, and phone
                                number. We refer to this information as “Order Information.”
                            </p>
                            <p>
                                We also collect electronic, audio, visual, or similar information, such as your
                                communications with us or photos, videos,
                                or other content you submit to us or publish in connection with our Services as well as
                                other
                                information that identifies or can be reasonably associated with you.
                            </p>
                            <p>When we talk about “Personal Information” in this Privacy Policy, we are talking both
                                about Device Information and Order Information.
                            </p>

                            <h4>How will we use your data?
                            </h4>
                            <p>We use the Order Information that we collect generally to fulfill any orders placed
                                through the Site (including processing your payment information, arranging for shipping,
                                and providing you with invoices and/or order confirmations). Additionally, we use this
                                Order Information to communicate with you, screen our orders for potential risk or fraud
                                and when in line with the preferences you have shared with us, provide you with
                                information or advertising relating to our products or services.
                            </p>
                            <p>We use the Device Information that we collect to help us screen for potential risk and
                                fraud (in particular, your IP address), and more generally to improve and optimize our
                                Site (for example, by generating analytics about how our customers browse and interact
                                with the Site, and to assess the success of our marketing and advertising campaigns).
                            </p>
                            <p>We share your Personal Information with third parties to help us use your Personal
                                Information, as described above. We also use Google Analytics to help us understand how
                                our customers use the Site--you can read more about how Google uses your Personal
                                Information <a href={"https://www.google.com/intl/en/policies/privacy/"}>here</a>. You
                                can also opt-out of Google Analytics <a
                                    href={"https://tools.google.com/dlpage/gaoptout"}>here</a>.
                            </p>
                            <p>Finally, we may also share your Personal Information to comply with applicable laws and
                                regulations, to respond to a subpoena, search warrant or other lawful request for
                                information we receive, or to otherwise protect our rights.
                            </p>
                            <h5>DO NOT TRACK</h5>
                            <p>Please note that we do not alter our Site’s data collection and use practices when we see
                                a "Do Not Track" signal from your browser.
                            </p>
                            <h4>How do we store your data?
                            </h4>
                            <p>Wall From Us securely stores your data on a private network in Amazon Web Services, where
                                it is only accessible through the Site’s servers.
                            </p>
                            <p>When you place an order through the Site, we will maintain your Order Information for our
                                records unless and until you ask us to delete this information.
                            </p>
                            <h4>Your Rights</h4>
                            <p>If you are a European resident, you have the right to access personal information we hold
                                about you and to ask that your personal information be corrected, updated, or deleted.
                                You also have the right to restrict or object to the company’s processing of your
                                personal data. If you would like to exercise any of these rights, please contact us by
                                e-mail at support@wallfromus.com.
                            </p>
                            <p>Additionally, if you are a European resident we note that we are processing your
                                information in order to fulfill contracts we might have with you (for example if you
                                make an order through the Site), or otherwise to pursue our legitimate business
                                interests listed above. Additionally, please note that your information will be
                                transferred outside of Europe, including to Canada and the United States.
                            </p>

                            <h5>
                                California Consumer Rights
                            </h5>
                            <p>
                                In this Statement, we have described the types of information we collect, the sources
                                from which we collect the information, and the ways in which we use the information. For
                                purposes of the California Consumer Privacy Act of 2018 ("CCPA"), the types of
                                information we collect fall into to following categories:</p>
                        <ul>
                            <li>Identifiers (e.g. name, mailing address, email address, phone number, credit/debit card
                                number).
                            </li>
                            <li>Characteristics of protected classifications (e.g. gender, age).</li>
                            <li>Commercial information (e.g. products or services purchased, purchase history).</li>
                            <li>Internet or other electronic network activity (e.g. browser or search history).</li>
                            <li>Geolocation data (e.g. latitude or longitude).</li>
                            <li>Audio, electronic, visual, or similar information (e.g. recording of guest service
                                calls).
                            </li>
                            <li>Professional or employment related information (e.g., occupation, job title, or
                                professional association membership.
                            </li>
                            <li>Inferences drawn from any of the above (e.g. preferences or characteristics).</li>
                        </ul>
                        <p>As described in this Statement, we collect this information from our consumers (through our
                            Services or our customer or technical support services) and from our vendors and other
                            service providers (including data analytics providers, advertising networks, and third-party
                            account verification services), and we use this information for the business or commercial
                            services described above.
                        </p>
                        <p>We do not sell your personal information to third parties, and we have not done so in the
                            twelve months prior to the date of this Statement. We also do not share your personal
                            information with third parties for their own direct marketing purposes.
                        </p>
                        <p>In addition, Wall From Us does not have actual knowledge that it sells or discloses the personal
                            information of minors under 16 years of age. To the extent that we become aware that such
                            sale or disclosure might be occurring, we will take steps to prevent such events, and/or we
                            will modify this Statement to describe those events and the related rights and choices of
                            our consumers.
                        </p>
                        <p>If you are a California "consumer," as defined by the CCPA, you may request that we:
                        </p>
                        <ul>

                            <li>Provide access to and/or a copy of certain personal information we have about you.</li>
                            <li>Provide information (up to twice in a 12-month period) about: the categories of personal
                                information we collect, disclose, or sell about you; the categories of sources of such
                                information; the business or commercial purpose for collecting or selling your personal
                                information; and the categories of third parties with whom we have shared personal
                                information during the past 12 months.
                            </li>
                            <li>Delete certain personal information we have about you.</li>
                        </ul>
                        <p>You may exercise these rights by submitting a request to us at support@wallfromus.com. In
                            reviewing and responding to such requests, we will undertake reasonable efforts to verify
                            your identity by, for example, matching any information you provide in the request with
                            information we maintain.
                        </p>
                        <p>
                            Please also note that most of these rights are not absolute, and our response may be limited
                            as permitted by the CCPA. For example, the right to have personal information deleted is
                            subject to a number of exceptions set out in the CCPA, such as when we need to maintain the
                            information to provide products or services you requested or to comply with our legal
                            obligations.

                        </p>
                        <p>Under the CCPA, California consumers also have the right not to receive discriminatory
                            treatment for exercising their other rights. Wall From Us will not discriminate against you
                            because you exercise your rights. For example, Wall From Us will not deny you goods or services
                            or charge you a different price or rate for goods or services if you make an access,
                            deletion, or do not sell request.
                        </p>
                        <h4>Marketing</h4>
                        <p>Wall From Us would like to send you information about products and services of ours that
                            we think you might like.
                        </p>
                        <p>If you have agreed to receive marketing, you may always opt out at a later date.
                        </p>
                        <p>You have the right at any time to stop Wall From Us from contacting you for marketing.
                        </p>
                        <h4>Cookies</h4>
                        <p>Cookies are text files placed on your computer to collect standard Internet log
                            information and visitor behavior information. When you visit our websites, we may
                            collect information from you automatically through cookies or similar technology.
                        </p>
                        <p>For further information, visit allaboutcookies.org.</p>
                        <h5>How do we use cookies?</h5>
                        <p>Wall From Us uses cookies in a range of ways to improve your experience on our website,
                            including:
                            <ul>
                                <li>Keeping you signed in</li>
                            </ul></p>
                        <h5>What types of cookies do we use?</h5>
                        <p>There are a number of different types of cookies, however, our website uses:
                            <ul>
                                <li>Functionality – Wall From Us uses these cookies so that we recognize you on our
                                    website and remember your previously selected preferences. These could include
                                    what language you prefer and location you are in. A mix of first-party and
                                    third-party cookies are used.
                                </li>
                            </ul>
                            <ul>
                                <li>Advertising – Wall From Us uses these cookies to collect information about your
                                    visit to our website, the content you viewed, the links you followed and
                                    information about your browser, device, and your IP address. Our Company
                                    sometimes shares some limited aspects of this data with third parties for
                                    advertising purposes. We may also share online data collected through cookies
                                    with our advertising partners. This means that when you visit another website,
                                    you may be shown advertising based on your browsing patterns on our website.
                                </li>
                            </ul>
                        </p>
                        <h5>How to manage cookies</h5>
                        <p>You can set your browser not to accept cookies, and the above website tells you how to
                            remove cookies from your browser. However, in a few cases, some of our website features
                            may not function as a result.
                        </p>
                        <h4>Privacy policies of other websites</h4>
                        <p>The Wall From Us website contains links to other websites. Our privacy policy applies
                            only to our website, so if you click on a link to another website, you should read their
                            privacy policy.
                        </p>
                        <h4>Changes to our privacy policy
                        </h4>
                        <p>Wall From Us keeps its privacy policy under regular review and places any updates on this
                            web page. This privacy policy was last updated on 31 Dec 2023.
                        </p>
                        <h4>How to contact us
                        </h4>
                        <p>If you have any questions about our privacy policy, the data we hold on you, or you would
                            like to exercise one of your data protection rights, please do not hesitate to contact
                            us via email at support@wallfromus.com.
                        </p>
                    </Col>
                </Row>

            </Container>
    </div>)
    }
}

export default withAuth0(PrivacyPolicyComponent);