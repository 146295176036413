
import React from "react";
import useGeoLocation from "react-ipgeolocation";
import {updateViewWallCountry} from "../actions";

const GeolocationComponent = (props) => {

    const location = useGeoLocation();

    if (location !== undefined && location.country !== "") {
        props.dispatch(updateViewWallCountry({
                country: location.country
            }
        ))
    }
    return null
};

export default GeolocationComponent;