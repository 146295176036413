import React, {Component} from "react";
import {Button, Modal, Row} from "react-bootstrap";
import {Redirect, withRouter} from "react-router-dom";
import axios from "axios";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowRight, faExclamationTriangle} from "@fortawesome/free-solid-svg-icons";
import {withAuth0} from "@auth0/auth0-react";
import {connect} from "react-redux";
import {updateEditingWall} from "../actions/edit";


class EditPaymentComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            redirectToConfirmation: false,
            uuid: this.props.match.params.uuid,
            freewall: this.props.match.params.freewall,
            errors: {},
            showErrorModal: false
        }

        this.payForWall = this.payForWall.bind(this);
        this.openModal = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.fetchWall = this.fetchWall.bind(this);
        this.activateFreeWall = this.activateFreeWall.bind(this);

    }

    payForWall(){
        let errors = {}

        let config = {
            headers: {
                Authorization: `Bearer ${this.props.token}`,
                "Access-Control-Allow-Origin": "*"
            }
        }

        axios.get(`${process.env.REACT_APP_SERVER_URL}payment/` + this.state.uuid + '/edit',
            config).then(result => {

                window.location = result.data
        }).catch(err => {
            errors = {
                "Server Error": err.toString(),
            }
            this.setState({errors: errors})
            this.openModal();
        })
    }

    activateFreeWall(){

        let errors = {}

        let config = {
            headers: {
                Authorization: `Bearer ${this.props.token}`,
                "Access-Control-Allow-Origin": "*"
            }
        }

        axios.post(`${process.env.REACT_APP_SERVER_URL}activateFreeWall/` + this.state.uuid + '/edit', {},
            config).then(result => {
            this.setState({
                redirectToConfirmation: true,
            })

        }).catch(err => {
            errors = {
                "Server Error": err.toString(),
            }
            this.setState({errors: errors})
            this.openModal();
        })

    }

    openModal() {
        this.setState({showErrorModal: true})
    }

    closeModal() {
        this.setState({showErrorModal: false})
    }

    fetchWall(uuid) {

        let config = {
            headers: {
                Authorization: `Bearer ${this.props.token}`,
                "Access-Control-Allow-Origin": "*"
            }
        }

        axios.get(`${process.env.REACT_APP_SERVER_URL}getWallByUuid/` + uuid,
            config).then(result => {

            let wall = result.data

            this.props.dispatch(
                updateEditingWall({
                        deliveryDate: wall.deliveryDate,
                        deliveryTime: wall.deliveryTime,
                        font: wall.font,
                        fontColour: wall.fontColour,
                        fontSize: wall.fontSize,
                        isActive: wall.active,
                        isCentred: wall.centred,
                        isDraft: wall.draft,
                        isPaid: wall.paid,
                        isPremium: wall.premium,
                        isSent: wall.sent,
                        isTransparent: wall.transparent,
                        recipientList: wall.recipientList,
                        wallTitle: wall.text,
                        s3Id: wall.s3Id,
                        s3Path: wall.s3Path,
                        uuid: wall.uuid,
                    }
                ))

        }).catch(err => {
            console.log(`Something went wrong ${err}`);
        })
    }

    render() {

        this.props.updateCurrentBreadcrumb("Payment")

        if (this.props.token !== undefined &&
            this.props.user !== undefined &&
            this.props.uuid === null
        ) {
            this.fetchWall(this.state.uuid)
        }

        const {redirectToConfirmation} = this.state

        if (redirectToConfirmation) {
            return (
                <Redirect to={'/editwall/confirmation/' + this.state.uuid + '/payment'}/>
            )
        }
        if (this.props.uuid === null) {

            return (<div>
                <br></br>
                <Row>
                    <h6>Loading</h6>
                </Row>
            </div>)
        }

        let freewall = this.state.freewall

        return (
            <div>
                <Modal show={this.state.showErrorModal} onHide={this.closeModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>Errors occurred <FontAwesomeIcon icon={faExclamationTriangle}/></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <h6>Server Error:</h6><p> An error occurred within the server</p>
                    </Modal.Body>
                </Modal>
                <br></br>
                { freewall !== "freewall" && this.props.isDraft &&
                <div>
                    <p>By activating your wall you agree to our <a className={"Homelinks"}
                                                                   href={`${process.env.REACT_APP_UI_URL}termsandconditions`}
                                                                   target="_blank">Terms of Service</a></p>
                    <Button onClick={()=> this.payForWall()}
                        style={{
                            backgroundColor: " #8639F1",
                            borderColor: " #8639F1",
                            width: "40%",
                            boxShadow: "0px 2px 5px -1px #333",
                        }}>
                    Pay and activate wall so others can contribute to it <FontAwesomeIcon icon={faArrowRight}/>

                </Button>
                </div>}
                { freewall === "freewall" && this.props.isDraft &&
                <div>
                    <p>By activating your wall you agree to our <a className={"Homelinks"}
                                                                   href={`${process.env.REACT_APP_UI_URL}termsandconditions`}
                                                                   target="_blank">Terms of Service</a></p>
                    <Button onClick={()=> this.activateFreeWall()}
                                                     style={{
                                                         backgroundColor: " #8639F1",
                                                         borderColor: " #8639F1",
                                                         boxShadow: "0px 2px 5px -1px #333",
                                                         width: "40%"
                                                     }}>
                    Activate free wall so others can contribute to it
                </Button>
                </div>}
                {!this.props.isDraft && freewall !== "freewall" && this.props.isPremium  && !this.props.isPaid &&
                <Button onClick={()=> this.payForWall()}
                        style={{
                            backgroundColor: " #8639F1",
                            borderColor: " #8639F1",
                            boxShadow: "0px 2px 5px -1px #333",
                            width: "40%"
                        }}>
                    Upgrade wall to premium
                </Button>
                }
                { // Shouldn't ever arrive here unless user is hacking URL
                    !this.props.isDraft && (freewall === "freewall" || this.props.isPaid) &&
                <div><p>Wall is already activated</p>
                    </div>}
            </div>
        )
    }
}
function mapStateToProps(state) {

    return {
        token: state.getIn(["centralState", "accessToken"]),
        user: state.getIn(["centralState", "user"]),
        uuid: state.getIn(["centralState", "editInProgress", "uuid"]),
    }
}

export default withRouter(connect(mapStateToProps)(EditPaymentComponent));