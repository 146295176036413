import Immutable from 'immutable';
import React from "react";
import {ReactSession} from "react-client-session";

const startState = Immutable.Map({
        centralState: {
            backgroundTile: "../images/100.jpg",
            createInProgress:{
                wallTitle: "",
                selectionStart: 0,
                selectionEnd: 0,
                font: "Comic sans MS",
                isTransparent: false,
                isConfetti: false,
                isCustomise: false,
                confettiColorsAndIds: [],
                isCentred: true,
                fontSize: 50,
                searchTags: [],
                searchResults: [],
                previouslySelected: [],
                isPremium: false,
                fontColour: "#000000",
                s3Id: 4,
                s3Path: null,
                tagOptions: [],
                currentBreadcrumb: "DesignWall",
                recipientList: [],
                deliveryDate: "",
                deliveryTime: 0
            },
            editInProgress:{
                uuid: null,
                wallTitle: "",
                selectionStart: 0,
                selectionEnd: 0,
                font: "Comic sans MS",
                isTransparent: false,
                isConfetti: false,
                isCustomise: false,
                confettiColorsAndIds: [],
                isCentred: true,
                fontSize: 50,
                searchTags: [],
                searchResults: [],
                previouslySelected: [],
                isPremium: false,
                fontColour: "#000000",
                s3Id: 4,
                s3Path: null,
                tagOptions: [],
                currentBreadcrumb: "DesignWall",
                recipientList: [],
                deliveryDate: "",
                deliveryTime: 0,
            },
            accessToken: "",
            user: null,
            viewWallCountry: ""
        }
})

ReactSession.setStoreType("localStorage");
let initialState = ReactSession.get("wallState");

let startingState;

if (initialState !== undefined){
    startingState  = Immutable.Map(initialState)
    startingState = startingState.setIn(["centralState", "createInProgress", "recipientList"], [])
    // editInProgress is never stored in local storage, to make it less confusing for user.
    startingState = startingState.setIn(["centralState", "editInProgress"], startState.getIn(["centralState","editInProgress"]))
} else {
    startingState = startState
}

const immutableStartingState = startingState;

function stripUnsuitableDataForLocalStorage(state){

    if (state === undefined){
        return
    }
    let reduxState = state.toJS();

    if (reduxState["centralState"] !== undefined){
        let createInProgDict = reduxState["centralState"]["createInProgress"]

        if (createInProgDict !== undefined){
            let {recipientList, ...remainder} = createInProgDict

            reduxState["centralState"]["createInProgress"] = remainder
        }

        let accessToken = reduxState["centralState"]["accessToken"]

        if (accessToken !== undefined) {
            let {accessToken, ...remainder} = reduxState["centralState"]
            reduxState["centralState"] = remainder
        }

        let user = reduxState["centralState"]["user"]

        if (user !== undefined) {
            let {user, ...remainder} = reduxState["centralState"]
            reduxState["centralState"] = remainder
        }

        let editInProgDict = reduxState["centralState"]["editInProgress"]

        if (editInProgDict !== undefined) {
            let {editInProgress, ...remainder} = reduxState["centralState"]
            reduxState["centralState"] = remainder
        }

        ReactSession.setStoreType("localStorage");
        ReactSession.set("wallState", reduxState);
    }
}

export default function central_reducer(state = immutableStartingState, action) {

    if (action.type === 'UPDATE_CREATE_PROGRESS_CARD_TITLE') {
        state = state.setIn(["centralState", "createInProgress", "wallTitle"], action.payload.wallTitle)
        state = state.setIn(["centralState", "createInProgress", "selectionStart"], action.payload.selectionStart)
        state = state.setIn(["centralState", "createInProgress", "selectionEnd"], action.payload.selectionEnd)
    }
    else if (action.type === 'UPDATE_CREATE_PROGRESS_FONT') {
        state = state.setIn(["centralState", "createInProgress", "font"], action.payload.font)
    }
    else if (action.type === 'UPDATE_IS_PREMIUM') {
        state = state.setIn(["centralState", "createInProgress", "isPremium"], action.payload.isPremium)
    }
    else if (action.type === 'UPDATE_CREATE_PROGRESS_SEARCH_TAGS') {
        state = state.setIn(["centralState", "createInProgress", "searchTags"], action.payload.searchTags)
    }
    else if (action.type === 'UPDATE_CREATE_PROGRESS_SEARCH_RESULTS') {
        state = state.setIn(["centralState", "createInProgress", "searchResults"], action.payload.searchResults)
    }
    else if (action.type === 'UPDATE_CREATE_PROGRESS_DEFAULT_WALL_SELECTED') {
        // If any fields are null, don't update them in redux, fields that are not null, e.g. font, should be update
        //directly here.

        let defaultWall = action.payload.defaultWall

        let previouslySelected = state.getIn(["centralState", "createInProgress", "previouslySelected"])

        previouslySelected.push(defaultWall)
        previouslySelected = previouslySelected.slice(-10)

        state = state.setIn(["centralState", "createInProgress", "previouslySelected"], previouslySelected)

        if (defaultWall.text !== null) {
            state = state.setIn(["centralState", "createInProgress", "wallTitle"], action.payload.defaultWall.wallTitle)
        }
        if (defaultWall.font !== null) {
            state = state.setIn(["centralState", "createInProgress", "font"], action.payload.defaultWall.font)
        }
        if (defaultWall.fontSize !== null) {
            state = state.setIn(["centralState", "createInProgress", "fontSize"], action.payload.defaultWall.fontSize)
        }
        if (defaultWall.fontColour !== null) {
            state = state.setIn(["centralState", "createInProgress", "fontColour"], action.payload.defaultWall.fontColour)
        }
        if (defaultWall.s3Id !== null) {
            state = state.setIn(["centralState", "createInProgress", "s3Id"], action.payload.defaultWall.s3Id)
        }
        if (defaultWall.s3Path !== null) {
            state = state.setIn(["centralState", "createInProgress", "s3Path"], action.payload.defaultWall.s3Path)
        }
        if (defaultWall.confettiColorsList.length > 0) {
            state = state.setIn(["centralState", "createInProgress", "confettiColorsAndIds"], action.payload.defaultWall.confettiColorsList)
        }
        if (defaultWall.centred !== null) {
            state = state.setIn(["centralState", "createInProgress", "isCentred"], action.payload.defaultWall.isCentred)
        }
        if (defaultWall.transparent !== null) {
            state = state.setIn(["centralState", "createInProgress", "isTransparent"], action.payload.defaultWall.isTransparent)
        }
        if (defaultWall.premium !== null) {
            state = state.setIn(["centralState", "createInProgress", "isPremium"], action.payload.defaultWall.isPremium)
        }
        if (defaultWall.confetti !== null) {
            state = state.setIn(["centralState", "createInProgress", "isConfetti"], action.payload.defaultWall.isConfetti)
        }
    }
    else if (action.type === 'UPDATE_CREATE_PROGRESS_IS_TRANSPARENT') {
        state = state.setIn(["centralState", "createInProgress", "isTransparent"], action.payload.isTransparent)
    }
    else if (action.type === 'UPDATE_CREATE_PROGRESS_IS_CONFETTI') {
        state = state.setIn(["centralState", "createInProgress", "isConfetti"], action.payload.isConfetti)
    }
    else if (action.type === 'UPDATE_CREATE_PROGRESS_IS_CUSTOMISE') {
        state = state.setIn(["centralState", "createInProgress", "isCustomise"], action.payload.isCustomise)
    }
    else if (action.type === 'UPDATE_CREATE_PROGRESS_CONFETTI_COLORS') {
        state = state.setIn(["centralState", "createInProgress", "confettiColorsAndIds"], action.payload.confettiColorsAndIds)
    }
    else if (action.type === 'UPDATE_CREATE_PROGRESS_IS_CENTRED') {
        state = state.setIn(["centralState", "createInProgress", "isCentred"], action.payload.isCentred)
    }
    else if (action.type === 'UPDATE_CREATE_PROGRESS_FONT_SIZE') {
        state = state.setIn(["centralState", "createInProgress", "fontSize"], action.payload.fontSize)
    }
    else if (action.type === 'UPDATE_CREATE_PROGRESS_FONT_COLOUR') {
        state = state.setIn(["centralState", "createInProgress", "fontColour"], action.payload.fontColour)
    }
    else if (action.type === 'UPDATE_CREATE_PROGRESS_CURRENT_BREADCRUMB') {
        state = state.setIn(["centralState", "createInProgress", "currentBreadcrumb"], action.payload.currentBreadcrumb)
    }
    else if (action.type === 'UPDATE_CREATE_PROGRESS_DELIVERY_DATE') {
        state = state.setIn(["centralState", "createInProgress", "deliveryDate"], action.payload.deliveryDate)
    }
    else if (action.type === 'UPDATE_CREATE_PROGRESS_DELIVERY_TIME') {
        state = state.setIn(["centralState", "createInProgress", "deliveryTime"], action.payload.deliveryTime)
    }
    else if (action.type === 'UPDATE_CREATE_PROGRESS_RECIPIENT_LIST') {
        state = state.setIn(["centralState", "createInProgress", "recipientList"], action.payload.recipientList)
    }
    else if (action.type === 'POPULATE_CREATE_PROGRESS_TAG_OPTIONS') {
        state = state.setIn(["centralState", "createInProgress", "tagOptions"], action.payload.options)
    }
    else if (action.type === 'RESET_CREATE_PROGRESS_WALL') {
        state = state.setIn(["centralState", "createInProgress"], startState.getIn(["centralState","createInProgress"]))
        // For some strange reason confettiColorsAndIds isn't being reset properly.
        // startState object seems to have confettiColorsAndIds set to it (which it shouldn't as it's immutable!) but only sometimes?
        state = state.setIn(["centralState", "createInProgress", "confettiColorsAndIds"], [])
    }
    else if (action.type === 'UPDATE_EDIT_PROGRESS_CARD_TITLE') {
        state = state.setIn(["centralState", "editInProgress", "wallTitle"], action.payload.wallTitle)
        state = state.setIn(["centralState", "editInProgress", "selectionStart"], action.payload.selectionStart)
        state = state.setIn(["centralState", "editInProgress", "selectionEnd"], action.payload.selectionEnd)
    }
    else if (action.type === 'UPDATE_EDIT_PROGRESS_FONT') {
        state = state.setIn(["centralState", "editInProgress", "font"], action.payload.font)
    }
    else if (action.type === 'UPDATE_EDIT_PROGRESS_IS_TRANSPARENT') {
        state = state.setIn(["centralState", "editInProgress", "isTransparent"], action.payload.isTransparent)
    }
    else if (action.type === 'UPDATE_EDIT_PROGRESS_IS_CONFETTI') {
        state = state.setIn(["centralState", "editInProgress", "isConfetti"], action.payload.isConfetti)
    }
    else if (action.type === 'UPDATE_EDIT_PROGRESS_IS_CUSTOMISE') {
        state = state.setIn(["centralState", "editInProgress", "isCustomise"], action.payload.isCustomise)
    }
    else if (action.type === 'UPDATE_EDIT_PROGRESS_CONFETTI_COLORS') {
        state = state.setIn(["centralState", "editInProgress", "confettiColorsAndIds"], action.payload.confettiColorsAndIds)
    }
    else if (action.type === 'UPDATE_EDIT_PROGRESS_IS_CENTRED') {
        state = state.setIn(["centralState", "editInProgress", "isCentred"], action.payload.isCentred)
    }
    else if (action.type === 'UPDATE_EDIT_PROGRESS_FONT_SIZE') {
        state = state.setIn(["centralState", "editInProgress", "fontSize"], action.payload.fontSize)
    }
    else if (action.type === 'UPDATE_EDIT_PROGRESS_FONT_COLOUR') {
        state = state.setIn(["centralState", "editInProgress", "fontColour"], action.payload.fontColour)
    }
    else if (action.type === 'UPDATE_EDIT_PROGRESS_CURRENT_BREADCRUMB') {
        state = state.setIn(["centralState", "editInProgress", "currentBreadcrumb"], action.payload.currentBreadcrumb)
    }
    else if (action.type === 'UPDATE_EDIT_PROGRESS_DELIVERY_DATE') {
        state = state.setIn(["centralState", "editInProgress", "deliveryDate"], action.payload.deliveryDate)
    }
    else if (action.type === 'UPDATE_EDIT_PROGRESS_DELIVERY_TIME') {
        state = state.setIn(["centralState", "editInProgress", "deliveryTime"], action.payload.deliveryTime)
    }
    else if (action.type === 'UPDATE_EDIT_PROGRESS_RECIPIENT_LIST') {
        state = state.setIn(["centralState", "editInProgress", "recipientList"], action.payload.recipientList)
    }
    else if (action.type === 'POPULATE_EDIT_PROGRESS_TAG_OPTIONS') {
        state = state.setIn(["centralState", "editInProgress", "tagOptions"], action.payload.options)
    }
    else if (action.type === 'UPDATE_EDIT_PROGRESS_IS_PREMIUM') {
        state = state.setIn(["centralState", "editInProgress", "isPremium"], action.payload.isPremium)
    }
    else if (action.type === 'UPDATE_EDIT_PROGRESS_SEARCH_TAGS') {
        state = state.setIn(["centralState", "editInProgress", "searchTags"], action.payload.searchTags)
    }
    else if (action.type === 'UPDATE_EDIT_PROGRESS_SEARCH_RESULTS') {
        state = state.setIn(["centralState", "editInProgress", "searchResults"], action.payload.searchResults)
    }
    else if (action.type === 'UPDATE_EDIT_PROGRESS_DEFAULT_WALL_SELECTED') {
        // If any fields are null, don't update them in redux, fields that are not null, e.g. font, should be update
        //directly here.

        let defaultWall = action.payload.defaultWall

        let previouslySelected = state.getIn(["centralState", "editInProgress", "previouslySelected"])

        previouslySelected.push(defaultWall)
        previouslySelected = previouslySelected.slice(-10)

        state = state.setIn(["centralState", "editInProgress", "previouslySelected"], previouslySelected)

        if (defaultWall.text !== null) {
            state = state.setIn(["centralState", "editInProgress", "wallTitle"], action.payload.defaultWall.wallTitle)
        }
        if (defaultWall.font !== null) {
            state = state.setIn(["centralState", "editInProgress", "font"], action.payload.defaultWall.font)
        }
        if (defaultWall.fontSize !== null) {
            state = state.setIn(["centralState", "editInProgress", "fontSize"], action.payload.defaultWall.fontSize)
        }
        if (defaultWall.fontColour !== null) {
            state = state.setIn(["centralState", "editInProgress", "fontColour"], action.payload.defaultWall.fontColour)
        }
        if (defaultWall.s3Id !== null) {
            state = state.setIn(["centralState", "editInProgress", "s3Id"], action.payload.defaultWall.s3Id)
        }
        if (defaultWall.s3Path !== null) {
            state = state.setIn(["centralState", "editInProgress", "s3Path"], action.payload.defaultWall.s3Path)
        }
        if (defaultWall.confettiColorsList.length > 0) {
            state = state.setIn(["centralState", "editInProgress", "confettiColorsAndIds"], action.payload.defaultWall.confettiColorsList)
        }
        if (defaultWall.centred !== null) {
            state = state.setIn(["centralState", "editInProgress", "isCentred"], action.payload.defaultWall.isCentred)
        }
        if (defaultWall.transparent !== null) {
            state = state.setIn(["centralState", "editInProgress", "isTransparent"], action.payload.defaultWall.isTransparent)
        }
        if (defaultWall.premium !== null) {
            state = state.setIn(["centralState", "editInProgress", "isPremium"], action.payload.defaultWall.isPremium)
        }
        if (defaultWall.confetti !== null) {
            state = state.setIn(["centralState", "editInProgress", "isConfetti"], action.payload.defaultWall.isConfetti)
        }
    }
    else if (action.type === 'UPDATE_EDIT_PROGRESS_WALL') {
        state = state.setIn(["centralState", "editInProgress"], Immutable.Map({...action.payload.wall,
            searchTags: [],
            searchResults: [],
            tagOptions: [],
            previouslySelected: []}))
    }
    else if (action.type === 'RESET_EDIT_PROGRESS_WALL') {
        state = state.setIn(["centralState", "editInProgress"], startState.getIn(["centralState","editInProgress"]))
        // For some strange reason confettiColorsAndIds isn't being reset properly.
        // startState object seems to have confettiColorsAndIds set to it (which it shouldn't as it's immutable!) but only sometimes?
        state = state.setIn(["centralState", "editInProgress", "confettiColorsAndIds"], [])
    }
    else if (action.type === 'UPDATE_ACCESS_TOKEN') {
        state = state.setIn(["centralState", "accessToken"], action.payload.accessToken)
    }
    else if (action.type === 'UPDATE_USER') {
        state = state.setIn(["centralState", "user"], action.payload.user)
    }
    else if (action.type === 'UPDATE_VIEW_WALL_COUNTRY') {
        state = state.setIn(["centralState", "viewWallCountry"], action.payload.country)
    }

    stripUnsuitableDataForLocalStorage(state)

    return state;
}